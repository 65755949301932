import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import state from './root';

Vue.use(Vuex);
const store = new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
});
export default store;
