/*
 * @Descripttion:
 * @Author: 宋爽爽
 * @Date: 2020-03-17 13:51:41
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-04-29 18:13:10
 */
import Vue from 'vue';
import router from '@/router/router';
import store from '@/store/index';
import collectData from '@/util/buryPoint';
import TextHighlight from 'vue-text-highlight';

import ElemetUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 导入由axios封装的ajax模块
import ajax from '@/assets/js/ajax';
// 导入rem计算模块
import '@/assets/js/rem';
// 导入 VueAwesomeSwiper 插件
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
// 引入vant
import { Lazyload, Image, Loading } from 'vant';

// 地图
import BaiduMap from 'vue-baidu-map';
import index from './index.vue';


Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'RkonaInfprG0ks0K7mq4OsBezsjgWbuR',
});

// options 为可选参数，无则不传
Vue.use(Image);
Vue.use(Loading);
Vue.use(Lazyload, {});

Vue.use(ElemetUI);
Vue.use(ajax);
Vue.use(VueAwesomeSwiper);
Vue.component('text-highlight', TextHighlight);
Vue.config.productionTip = false;

// 收集埋点数据
router.afterEach((to) => {
  collectData(to, '_product');
});

new Vue({
  router,
  store,
  render: h => h(index),
}).$mount('#je-common-detail');
