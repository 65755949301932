<template>
  <div class="float_btn_phone">
    <div class="float_btn_phone_content">
      <dialogtitle
        :text="'电话联系'"
        @handleClose="handleClose"
      />
      <div class="phone_wrp">
        <img
          :src="phone"
          class="phone"
        >
        <div class="phone_text_wrp">
          <div class="phone_text">
            欢迎致电，我们将随时为您提供帮助!
          </div>
          <div class="phone_num">
            400-0999-235
          </div>
        </div>
      </div>
      <div class="consult_wrp">
        <div
          v-for="(item, index) in consultlist"
          :key="index"
          class="consult_items"
        >
          <p class="type">
            {{ item.type }}
          </p>
          <div class="persons_wrp">
            <div
              v-for="person in item.persons"
              :key="person"
              class="persons"
            >
              {{ person }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dialogtitle from './dialogtitle';
import phone from '../assets/imgs/phone.png';

export default {
  name: 'FloatBtnPhone',
  components: { dialogtitle },
  data() {
    return {
      phone,
      consultlist: [
        {
          type: '售前咨询',
          persons: ['石经理：18519706638', '赵经理：17610358522'],
        },
        {
          type: '技术咨询',
          persons: ['张先生：13131059599', '云先生：18610941078'],
        },
      ],
    };
  },
  methods: {
    handleClose() {
      this.$emit('handleClose', 'phone');
    },
  },
};
</script>

<style lang="less" scoped>
  .float_btn_phone {
    padding: 35px 60px 70px;
    p,
    div {
      margin: 0;
      text-align: left;
    }
    > .float_btn_phone_content {
      width: 386px;
      > .phone_wrp {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        margin-top: 20px;
        > .phone {
          width: 70px;
          height: 70px;
          padding: 10px;
        }
        > .phone_text_wrp {
          color: #333333;
          > .phone_text {
            font-size: 16px;
          }
          > .phone_num {
            font-size: 40px;
            height: 40px;
            // margin-top: 15px;
          }
        }
      }
      > .consult_wrp {
        > .consult_items {
          background: #F0F0F0;
          padding: 25px 30px;
          color: #333333;
          margin-top: 25px;
          > .type {
            font-size: 18px;
            font-weight: bold;
          }
          > .persons_wrp {
            margin-top: 20px;
            font-size: 14px;
            > .persons {
              width: 50%;
              float: left;
            }
            &:after {
              content: '';
              display: table;
              clear: both;
            }
          }
        }
      }
    }
  }
</style>
