<template>
  <div class="jepass-buy-video">
    <el-dialog
      :visible.sync="showVideo_"
      width="50%"
      custom-class="buy-video"
      append-to-body
    >
      <span
        slot="title"
        class="color-3F3F3F font-size-24 font-weight-500"
      >购买视频</span>
      <div class="padding-left-20 margin-top-10 background-FFF7E9">
        <div
          class="color-B5B4B4 font-size-18 color-EF4D4D padding-TB-10"
          v-if="product.hint"
        >
          {{ product.hint }}
        </div>
      </div>
      <div class="margin-top-20">
        <el-row>
          <el-col :span="10">
            <img
              v-lazy="product.image"
              class="videoImg"
            >
          </el-col>
          <el-col :span="14">
            <div class="padding-left-40">
              <div
                class="color-3F3F3F font-weight-600 font-size-18"
              >
                {{ product.title }}
              </div>
              <div class="margin-top-10">
                <el-tag
                  v-for="(item, _index) in product.labels"
                  :key="_index"
                  :class="_index != 0 && 'margin-left-20'"
                  :type="_index%2 == 0 ? 'success' : 'info'"
                >
                  {{ item }}
                </el-tag>
              </div>
              <div class="margin-top-20">
                <div class="color-F9524F font-size-30">
                  ¥{{ product.money.toFixed(2) }}
                </div>
              </div>
              <div class="margin-top-20">
                <PayButtons
                  @openPhoneDialog="openPhone"
                  :form-data="choosedPrice"
                  :product-type="'ZYZL'"
                  :lines="'one'"
                  :buttons="''"
                />
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import PayButtons from './payButtons';

export default {
  components: {
    PayButtons,
  },
  props: {
    videoDetails: {
      type: Object,
      default() {
        return {};
      },
    },
    showVideo: {
      type: Boolean,
      default() {
        return false;
      },
    },
    orderDetails: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      downLoadText: '666',
      persons: [],
      choosedPrice: {},
    };
  },
  computed: {
    showVideo_: {
      get() {
        return this.showVideo || false;
      },
      set(val) {
        this.$emit('closePayVideo', val);
      },
    },
    /**
     * 商品信息
     */
    product() {
      const product = {
        code: this.videoDetails.PRODUCT_SPBH, // 商品编码
        title: this.videoDetails.PRODUCT_SPMC, // 商品名称
        hint: this.videoDetails.PRODUCT_TSSM || '【温馨提示】观看完整视频请升级VIP服务或购买整套视频。', // 温馨提示
        payBtns: this.videoDetails.PRODUCT_ZFFS_CODE, // 支付按钮显示内容
        labels: this.videoDetails.PRODUCT_SPBQ && this.videoDetails.PRODUCT_SPBQ.split(','), // 商品的标签
        money: this.videoDetails.PRODUCT_CKJG || 0, // 商品价格
        image: `/jepaas/document/preview?fileKey=${(this.videoDetails.PRODUCT_TB || '').split('*')[1]}`, // 商品图片
      };
      return product;
    },
  },
  watch: {
    product: {
      deep: true,
      handler(newVal) {
        this.choosedPrice.price = newVal.money;
        this.choosedPrice.payBtns = newVal.payBtns;
        this.choosedPrice.shopCode = newVal.code;
      },
    },
  },
  created() {},
  mounted() {},
  updated() {},
  methods: {
    // 打开联系电话弹窗
    openPhone() {
      this.persons = [
        { name: '云经理', phone: '18610941078', order: 1 },
        { name: '赵经理', phone: '17610358522', order: 2 },
        { name: '石经理', phone: '15010612217', order: 3 },
      ];
      // 随机排序
      this.persons.sort(() => Math.random() - 0.5);
      this.$refs.phoneDialog.phoneDialogVisible = true;
    },
    // closeBuy() {
    //   this.$emit('closePayVideo');
    // },
  },
};
</script>

<style lang="less">
.buy-video {
  .payBtn {
    text-align: left !important;
    .el-button {
      min-width: 100px;
      font-size: 14px;
      padding: 10px 15px;
      margin-left: 8px!important;
    }
  }
}
.buy-video {
    box-shadow: none;
    border-radius: 8px;
    .el-dialog__body {
      padding-top: 0!important;
      padding-bottom: 40px !important;
    }
  }
</style>
<style rel="stylesheet/scss" lang="less" scoped>
.videoImg {
    width: 100%;
    height: auto;
    background: black;
  }
@import '../../index/style/shop/util';
</style>
