/*
 * @Descripttion:
 * @Author: 宋爽爽
 * @Date: 2019-09-20 19:41:02
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-03-21 14:12:28
 */

const actions = {
  // changeHome (context) {
  //   context.commit('showHome')
  // },
};
export default actions;
