<template>
  <div class="pay-btns">
    <div
      v-if="lines == 'one'"
      class="payBtn"
      style="text-align: right;"
    >
      <el-button
        v-for="item in payBtns"
        :key="item.order"
        :type="item.color"
        :loading="item.payLoading"
        @click="doPay(item.type)"
      >
        {{ item.text }}
      </el-button>
    </div>
    <div
      v-if="lines == 'two'"
      class="payBtn-two"
    >
      <el-button
        v-for="item in payBtns"
        :key="item.order"
        :type="item.color"
        :loading="item.payLoading"
        @click="doPay(item.type)"
      >
        {{ item.text }}
      </el-button>
    </div>
    <!-- <div
      v-if="lines == 'three'"
      class="payBtnThree"
      style="text-align: left;"
    >
      <el-button
        type="success"
        size="small"
        :loading="wechatpayLoading"
        @click="doPay('wechat')"
      >
        微信支付
      </el-button>
      <el-button
        size="small"
        type="primary"
        :loading="alipayLoading"
        @click="doPay('ali')"
      >
        支付宝支付
      </el-button>
      <el-button
        size="small"
        type="warning"
        @click="doPay('XXZF')"
      >
        线下支付
      </el-button>
      <el-button
        size="small"
        :loading="btloading"
        @click="doPay('BTZF')"
      >
        白条支付
      </el-button>
    </div> -->
    <PayDialog
      ref="payDialog"
      :payUrl="formData"
      v-on="$listeners"
    />
    <el-dialog
      :visible.sync="IOUVisible"
      width="40%"
      append-to-body
    >
      <div
        slot="title"
        class="font-size-20 font-weight-600"
      >
        白条支付提示
      </div>
      <div class="text-status-center">
        <img
          src="../assets/images/pay/btTip.png"
          alt=""
        >
      </div>
      <div class="color-3F3F3F font-size-16 padding-left-40 padding-right-40 margin-top-20">
        <div>您未开通白条资格，需具备如下部分条件后，联系销售人员开通白条权限：</div>
        <div class="margin-top-40">
          1、您符合JEPaaS平台优质客户资质。
        </div>
        <div class="margin-top-10">
          2、您在JEPaaS平台消费已超过50万元。
        </div>
        <div class="margin-top-10">
          3、您使用JEPaaS平台累计时间超过2年。
        </div>
        <div class="margin-top-40">
          如有任何疑问，请联系销售进行询问及开通白条资格。
        </div>
        <div class="margin-top-20">
          <i class="jeicon jeicon-the-phone vertical-align-middle font-size-16 color-FBB11A margin-right-5" />
          <span class="margin-left-5 vertical-align-middle">石经理：</span>
          <span class="color-FFA200 vertical-align-middle">15010612217</span>
        </div>
      </div>
    </el-dialog>


    <el-dialog
      :visible.sync="payVisible"
      width="558px"
      append-to-body
      :show-close="false"
      :close-on-click-modal="false"
      custom-class="pay-tips"
    >
      <div
        slot="title"
        class="font-size-20 font-weight-600"
      >
        白条支付提示
      </div>
      <div class="tip-img">
        <img
          src="../assets/images/pay/btPay.png"
          alt=""
        >
      </div>
      <div class="tip-word">
        您确认使用白条支付方式购买该商品？
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="small"
          type="danger"
          :loading="JEpayLoading"
          @click="payByIOU"
        >确 定</el-button>
        <el-button
          size="small"
          @click="payVisible = false"
        >取 消</el-button>
      </span>
    </el-dialog>


    <el-dialog
      :visible.sync="nopayDialogVisible"
      width="558px"
      :show-close="false"
      append-to-body
      :before-close="handleClose"
      custom-class="pay-tips"
    >
      <div
        slot="title"
        class="font-size-20 font-weight-600"
      >
        提示
      </div>
      <div class="tip-img">
        <img
          src="../assets/images/pay/order.png"
          alt=""
        >
      </div>
      <div class="tip-word">
        该商品存在待支付订单，请处理！
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="small"
          :loading="reloading"
          @click="doReorder"
        >重新下单</el-button>
        <el-button
          size="small"
          type="danger"
          @click="goOrders"
        >前往订单</el-button>
      </span>
    </el-dialog>
    <!-- 已购买过该组件提示 -->
    <el-dialog
      :visible.sync="payZS"
      width="558px"
      custom-class="pay-tips"
      :close-on-click-modal="false"
    >
      <div
        slot="title"
        class="font-size-20 font-weight-600 text-status-left"
      >
        提示
      </div>
      <div class="tip-word font-size-20">
        此证书："<span class="font-weight-600">{{ formData.zsNumber }}</span>" 已购买该功能, 不可重复购买!
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="small"
          type="danger"
          @click="payZS = false"
        >确定</el-button>
      </span>
    </el-dialog>
    <!-- 前往登陆的提示 -->
    <el-dialog
      :visible.sync="goLogin"
      width="558px"
      custom-class="pay-tips"
      :close-on-click-modal="false"
    >
      <div
        slot="title"
        class="font-size-20 font-weight-600 text-status-left"
      >
        提示
      </div>
      <div class="tip-word font-size-20">
        您还未登录用户无法进行购买，请先前往登录
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="small"
          type="danger"
          @click="goLoginUrl"
        >前往</el-button>
      </span>
    </el-dialog>
    <!-- 微信支付大于3000.00提示 -->
    <el-dialog
      :visible.sync="maxPay3000"
      width="800px"
      custom-class="pay-tips maxPay3000"
      :close-on-click-modal="false"
    >
      <div
        slot="title"
        class="font-size-20 font-weight-600 text-status-left"
      >
        提示
      </div>
      <div class="text-status-center">
        <img
          src="../assets/images/pay/btTip.png"
          alt=""
        >
      </div>
      <div class="font-size-18 color-3F3F3F margin-top-20 text-status-center">
        因受微信支付限制，该商品超过3000.00元，建议您使用支付宝或线下支付！
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <div
          class="max-btns padding-bottom-20"
        >
          <div
            v-for="(item, _item) in payBtns"
            :key="item.order"
            :class="_item > 0 && 'margin-left-30'"
          >
            <el-button
              v-if="item.type != 'wechat' && item.type != 'BTZF'"
              :type="item.color"
              :loading="item.payLoading"
              @click="doPay(item.type)"
            >
              {{ item.text }}
            </el-button>
          </div>
        </div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  setStorage, arraySort, clearSpace, checkCharacter, checkNumber, checkFloat,
} from '@/util/util';
import { getToken } from '@/util/auth';
import {
  addOrder, startPay, removeOrder, getIOU,
} from '@/pages/detail/actions/actions';
import PayDialog from './payDialog';

export default {
  name: 'PayButtons',
  components: {
    PayDialog,
  },
  props: {
    lines: {
      type: String,
      default: 'one',
    },
    productType: {
      type: String,
      default: 'SYZS',
    },
    formData: {
      type: Object,
      default() {
        return {};
      },
    },
    formInput: {
      type: Object,
      default() {
        return {};
      },
    },
    copyrights: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      maxPay3000: false, // 微信支付大于3000
      goLogin: false, // 前往登陆的提示
      payZS: false, // 当前证书是否已经购买过该组件弹框控件
      hasIOUlimit: true, // 是否有白条额度
      IOUVisible: false, // 白条提示
      payVisible: false, // 支付提示
      nopayDialogVisible: false, // 有未支付订单 的弹窗提示
      wechatpayLoading: false, // 微信支付按钮loading
      alipayLoading: false, // 支付宝支付按钮loading
      btloading: false, // 白条支付按钮loading
      JEpayLoading: false, // 白条支付弹窗确定按钮loading
      reloading: false, // 重新下单按钮loading
      orderId: '', // 已有未支付订单的订单ID
      payType: '', // 选择的支付方式
      payBtns: [],
      fullscreenLoading: null,
    };
  },
  // 声明依赖  让子组件paydialog 获取这个类型
  provide() {
    return {
      productType: this.productType,
    };
  },
  mounted() {
    this.getBtns();
  },
  methods: {
    // 获取支付按钮
    getBtns() {
      if (this.formData.payBtns) {
        const btns = [];
        const types = this.formData.payBtns.split(',');
        types.forEach((type) => {
          switch (type) {
            case 'wechat':
              btns.push({
                type,
                text: '微信支付',
                order: 1,
                payLoading: false,
                color: 'success',
              });
              break;
            case 'ali':
              btns.push({
                type,
                text: '支付宝支付',
                order: 2,
                payLoading: false,
                color: 'primary',
              });
              break;
            case 'XXZF':
              btns.push({
                type,
                text: '线下支付',
                order: 3,
                payLoading: false,
                color: 'warning',
              });
              break;
            case 'BTZF':
              btns.push({
                type,
                text: '白条支付',
                order: 4,
                payLoading: false,
                color: '',
              });
              break;
            default:
              break;
          }
        });
        this.payBtns = arraySort(btns, 'order');
      } else {
        this.payBtns = [
          {
            type: 'wechat', text: '微信支付', order: 1, payLoading: false, color: 'success',
          },
          {
            type: 'ali', text: '支付宝支付', order: 2, payLoading: false, color: 'primary',
          },
          {
            type: 'XXZF', text: '线下支付', order: 3, payLoading: false, color: 'warning',
          },
          {
            type: 'BTZF', text: '白条支付', order: 4, payLoading: false, color: '',
          },
        ];
      }
    },
    // 前往登陆注册
    goLoginUrl() {
      this.goLogin = false;
      this.$router.push('/login');
    },
    // 点击支付按钮  处理逻辑
    doPay(type) {
      // 如果支付的价格小于等于0
      if (!this.formData.price && this.formData.zsType == 'SJZS') {
        return this.$message.error('请选择正确的升级证书配置');
      }
      if (!getToken()) {
        return this.goLogin = true;
      }
      // 商业证书的校验必填项
      if (this.productType == 'SYZS') {
        // form表单的验证
        this.$emit('validateForm');
        if (!this.formInput.topFont.trim()) {
          return false;
        }
        if (checkCharacter(clearSpace(this.formInput.topFont))) {
          return false;
        } if (checkNumber(clearSpace(this.formInput.topFont)) || !checkFloat(clearSpace(this.formInput.topFont))) {
          return false;
        }

        if (!this.formInput.loadFont.trim()) {
          return false;
        }
        if (checkCharacter(clearSpace(this.formInput.loadFont))) {
          return false;
        } if (checkNumber(clearSpace(this.formInput.loadFont)) || !checkFloat(clearSpace(this.formInput.loadFont))) {
          return false;
        }

        if (!this.formInput.comFont.trim()) {
          return false;
        }
        if (checkCharacter(clearSpace(this.formInput.comFont))) {
          return false;
        } if (checkNumber(clearSpace(this.formInput.comFont)) || !checkFloat(clearSpace(this.formInput.comFont))) {
          return false;
        }
      } else {
        if (!this.formData.zsNumber && this.copyrights > 0) {
          return this.$message({
            type: 'warning',
            message: '请先选择商业证书',
          });
        }
        if (this.copyrights == 0) {
          this.$emit('zsNumberShow');
          return;
        }
      }
      // 如果是资源资料的话下单进行校验
      if (this.productType == 'ZQZJ' && this.formData.payZQZJStatus) {
        this.payZS = true;
        return;
      }
      // 如果是微信支付并且大于3000.00就要提示用别的支付方式
      if (this.formData.price > 3000 && type == 'wechat') {
        this.maxPay3000 = true;
        return;
      }
      this.maxPay3000 = false;

      this.payType = type;
      switch (type) {
        // 微信支付
        case 'wechat':
          this.payBtns.forEach((item, index) => {
            if (item.type == 'wechat') {
              this.payBtns[index].payLoading = true;
            }
          });
          this.startPaying(type, (orderInfo, payinfo) => {
            this.$refs.payDialog.qrcodeImg = payinfo.data;
            this.$refs.payDialog.title = '微信支付提示';
            this.$refs.payDialog.amountPayable = orderInfo.DDGL_DDJG;
            this.$refs.payDialog.payTime = payinfo.date; // 二维码生成时间
            this.$refs.payDialog.payComputeTime(); // 开始倒计时 DDGL_DDH
            this.$refs.payDialog.orderId = orderInfo.JEPAAS_DDGL_ID; // 订单ID
            this.$refs.payDialog.orderCode = orderInfo.DDGL_DDH; // 订单编号
            setTimeout(() => {
              this.payBtns.forEach((item, index) => {
                if (item.type == 'wechat') {
                  this.payBtns[index].payLoading = false;
                }
              });
              this.fullscreenLoading.close();
              this.$refs.payDialog.payVisible = true;
            }, 500);
          });
          break;
        // 支付宝支付
        case 'ali':
          this.payBtns.forEach((item, index) => {
            if (item.type == 'ali') {
              this.payBtns[index].payLoading = true;
            }
          });
          this.startPaying(type, (orderInfo, payinfo) => {
            const newTab = window.open('about:blank');
            const div = document.createElement('div');
            // newTab.document.charset = 'UTF-8';
            div.innerHTML = payinfo.data;
            newTab.document.body.appendChild(div);
            newTab.document.forms.alipaysubmit.submit();
            this.$refs.payDialog.title = '支付宝支付提示';
            this.$refs.payDialog.amountPayable = orderInfo.DDGL_DDJG;
            this.$refs.payDialog.orderId = orderInfo.JEPAAS_DDGL_ID; // 订单ID
            this.$refs.payDialog.orderCode = orderInfo.DDGL_DDH; // 订单编号
            setTimeout(() => {
              this.payBtns.forEach((item, index) => {
                if (item.type == 'ali') {
                  this.payBtns[index].payLoading = false;
                }
              });
              this.fullscreenLoading.close();
              this.$refs.payDialog.payVisible = true;
            }, 500);
          });
          break;
        // 线下支付
        case 'XXZF':
          this.$emit('openPhoneDialog');
          break;
        // 白条支付
        case 'BTZF':
          this.payByBT(type);
          break;
        default:
          break;
      }
    },
    // 点击白条支付后  逻辑处理
    payByBT(type) {
      // 获取是否有白条额度
      this.payBtns.forEach((item, index) => {
        if (item.type == type) {
          this.payBtns[index].payLoading = true;
        }
      });
      getIOU().then((result) => {
        this.payBtns.forEach((item, index) => {
          if (item.type == type) {
            this.payBtns[index].payLoading = false;
          }
        });
        if (!result.success) {
          // 无白条权限 提示
          if (result.errorCode == '100002') {
            return this.IOUVisible = true;
          }
          return this.$message({
            type: 'error',
            message: result.message || '查询白条额度失败！',
            duration: 5000,
          });
        }
        /* // 若果不是商业证书  并且没有选择配置 金额未知 （增强组件这么判断没问题，vip、资源资料等等不是我做的，有可能会有问题，明尧酱斟酌修改，或者找我）
        if (isNaN(Number(this.formData.price)) && this.productType != 'SYZS' && this.productType != 'DEFAULT') {
          return this.$message({
            type: 'warning',
            message: '请选择配置！',
            duration: 5000,
          });
        } */
        // 拿系统余额跟当前需支付总价格对比：如果足够支付，就下单。不足以支付，做出提示！
        if (result.obj && Number(result.obj.BTGL_KYED) && (Number(result.obj.BTGL_KYED) > Number(this.formData.price))) {
          this.addOrder(type, (data) => {
            // 获取订单ID 等等信息
            this.payVisible = true;
            this.orderId = data.obj.JEPAAS_DDGL_ID;
          });
        } else {
          this.$message({
            type: 'warning',
            message: '您当前额度不足以支付此次订单金额，请联系销售人员协商处理！',
            duration: 5000,
          });
        }
      });
    },
    // 白条支付弹窗  点击确定
    payByIOU() {
      // 直接支付 获取支付状态 成功跳转成功页面 失败跳转失败页面
      this.JEpayLoading = true;
      const productType = this.formatProduct();
      startPay({
        orderId: this.orderId,
        productType,
        type: 'BTZF',
        configurationId: this.formData.shopId || '', // 如果是vip支付就要传递该ID
        transactionType: this.transactionTypePro(),
      }).then((result) => {
        if (result.success) {
          // 处理当前商品页存入store 方便支付成功后的页面展示
          this.$store.commit('setProductType', this.productType);
          setStorage('local', 'jepaasStore', this.$store.state);
          // 有可能是购买玩商业证书后，有进行购买了VIP服务 所以要重新刷新页面数据
          this.$route.path == '/paySuccess' && this.$router.go(0) || this.$router.push({ path: '/paySuccess' });
        } else {
          this.$router.push({ path: '/payFailure' });
          this.$message({
            type: 'error',
            message: result.message,
          });
        }
        this.JEpayLoading = false;
        this.payVisible = false;
      });
    },
    // 开始支付
    startPaying(type, callback) {
      this.fullscreenLoading = this.$loading({
        lock: true,
        text: '拼命加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)',
        customClass: 'je-pay-loading',
      });
      this.addOrder(type, (data) => {
        // 白条支付
        if (type == 'BTZF') {
          return callback(data);
        }
        // 其他支付
        const productType = this.formatProduct();
        startPay({
          orderId: data.obj.JEPAAS_DDGL_ID,
          configurationId: this.formData.shopId || '', // 如果是vip支付就要传递该ID
          productType,
          type,
          transactionType: this.transactionTypePro(),
        }).then((result) => {
          if (!result.success) {
            this.payBtns.forEach((item, index) => {
              this.payBtns[index].payLoading = false;
            });
            this.fullscreenLoading.close();
            if (result.errorCode == '5101') {
              return this.$message({
                type: 'error',
                message: '当前支付方式异常，请切换支付方式，或者稍后重试！',
              });
            }
            return this.$message({
              type: 'error',
              message: result.message,
            });
          }
          return callback(data.obj, result.obj);
        });
      });
    },
    // 接口SPZF*  VIP* DS*等等信息过滤
    transactionTypePro() {
      let productType = 'SPZF*';
      switch (this.productType) {
        case 'VIP':
          productType = 'VIP*';
          break;
        case 'DS':
          productType = 'DS*';
          break;
        case 'ZYZL':
          productType = 'ZYZL*';
          break;
        case 'SJZS':
          productType = 'SJZS*';
          break;
      }
      return productType;
    },
    // 产品类型 type 转 name
    formatProduct() {
      let productType = '商业证书';
      switch (this.productType) {
        case 'SYZS':
          productType = '商业证书';
          break;
        case 'SJZS':
          productType = '升级证书';
          break;
        case 'ZQZJ':
          productType = '增强组件';
          break;
        case 'ZYZL':
          productType = '资源资料';
          break;
        case 'RGFW':
          productType = '人工服务';
          break;
        case 'HYCP':
          productType = '行业产品';
          break;
        case 'VIP':
          productType = 'VIP服务';
          break;
        case 'DS':
          productType = '打赏';
          break;
        case 'DEFAULT':
          productType = '人工服务';
          break;
        default:
          break;
      }
      return productType;
    },
    // 重新下单
    doReorder() {
      // 先取消之前的订单
      removeOrder({
        orderId: this.orderId,
      }).then((result) => {
        if (result.success) {
          // 重新下单支付
          this.nopayDialogVisible = false;
          if (this.payType == 'BTZF') {
            this.payByBT('BTZF');
          } else {
            this.doPay(this.payType);
          }
        }
      });
    },
    // 跳转订单
    goOrders() {
      this.fullscreenLoading && this.fullscreenLoading.close();
      this.nopayDialogVisible = false;
      this.$router.push({
        path: '/alreadyList',
      });
    },
    handleClose(done) {
      this.fullscreenLoading.close();
      done();
    },
    // 支付前下单
    addOrder(type, callback) {
      addOrder(this.getParams(type)).then((result) => {
        if (result.success) {
          return callback(result);
        }
        this.payBtns.forEach((item, index) => {
          this.payBtns[index].payLoading = false;
        });
        // errorCallback(result.errorCode);
        if (result.errorCode == '100001') {
          this.orderId = result.obj;
          this.nopayDialogVisible = true;
        }
      });
    },
    // 处理参数
    getParams(type) {
      const params = {
        type: this.productType == 'SJZS' && 'SYZS' || this.productType,
        zsCode: this.formData.zsCode || '', // 升级证书需要传递的证书编号
        zsType: this.formData.zsType || '', // 购买证书 升级证书必传
        payType: type,
        remark: this.formData.leaveMessage || '', // 打赏的留言
        count: this.formData.count || '', // 人工服务的购买单价数量
        configurationId: this.formData.shopId || '', // 如果是vip支付就要传递该ID
        userCount: JSON.stringify(this.formData.userCount),
        flowCount: JSON.stringify(this.formData.flowCount),
        funcCount: JSON.stringify(this.formData.funcCount),
        machineCount: JSON.stringify(this.formData.machineCount),
        tableCount: JSON.stringify(this.formData.tableCount),
        topLink: JSON.stringify({
          code: 'DBLJ',
          name: '顶部链接',
          value: this.formInput.topLink || '',
        }),
        topWord: JSON.stringify({
          code: 'DBXSZ',
          name: '顶部显示字',
          value: this.formInput.topFont || '',
        }),
        companyInfo: JSON.stringify({
          code: 'GSXXZY',
          name: '公司信息字样',
          value: this.formInput.comFont || '',
        }),
        dataWord: JSON.stringify({
          code: 'SJJZZY',
          name: '数据加载字样',
          value: this.formInput.loadFont || '',
        }),
      };
      // 不是商业证书  多两个参数  页面url、规则id、商业证书编号......
      if (this.productType != 'SYZS' && this.productType != 'SJZS') {
        params.productCode = this.formData.shopCode;
        params.payZQZJStatus = this.formData.payZQZJStatus || false; // 增强组件已经购买过显示弹框提示
        params.pluginCode = this.formData.pluginCode || '';
        delete params.topLink;
        delete params.topWord;
        delete params.companyInfo;
        delete params.dataWord;
        // 规则id
        // 商业证书编号
        params.zsNumber = this.formData.zsNumber;
      }
      return params;
    },
  },
};
</script>
<style lang="less">
.maxPay3000 {
  // .payBtn {
  //   display: flex;
  //   justify-content: space-between;
  // }
}
.max-btns {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.pay-btns {
  .payBtn {
    margin-bottom: 15px;
    text-align: center;
    .el-button {
      min-width: 130px;
      font-size: 16px;
      .el-icon-loading {
        position: absolute;
        left: 50%;
        margin-left: -8px;
        color: #f84f4e;
      }
    }
    .el-button + .el-button {
      margin-left: 15px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .payBtn-two {
    text-align: center;
    .el-button {
      min-width: 130px;
      font-size: 16px;
      margin-bottom: 15px;
      .el-icon-loading {
        position: absolute;
        left: 50%;
        margin-left: -8px;
        color: #f84f4e;
      }
      &:nth-child(2n) {
        margin-left: 15px !important;
      }
    }
    .el-button + .el-button {
      margin-left: 0;
    }
  }
  .payBtnThree {
    .el-button + .el-button {
      margin-left: 10px;
    }
  }
}
.pay-tips {
  .el-dialog__body {
    padding: 0 20px;
  }
  .tip-img {
    font-size: 0;
    text-align: center;
  }
  .tip-word {
    font-size: 14px;
    font-weight: 400;
    color: rgba(63, 63, 63, 1);
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .tip-btword {
    font-size: 14px;
    font-weight: 400;
    color: rgba(63, 63, 63, 1);
    margin-top: 10px;
    pre {
      line-height: 30px;
      padding: 0 20px;
      i {
        color: #fc6251;
        margin-right: 10px;
      }
      .phoneNum {
        color: #faa71f;
      }
    }
  }
  .el-dialog__footer {
    text-align: center;
    .el-button--small {
      min-width: 120px;
      .el-icon-loading {
        position: absolute;
        left: 50%;
        margin-left: -6px;
        color: #3f3f3f;
      }
    }
    .el-button + .el-button {
      margin-left: 40px;
    }
  }
}
.qrcode-dialog {
  .tip-img {
    font-size: 0;
    text-align: center;
  }
}
.je-pay-loading {
  .el-loading-spinner {
    i {
      font-size: 50px;
    }
    .el-loading-text {
      font-size: 24px;
    }
  }
}
</style>
