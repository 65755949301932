/*
 * @Descripttion:
 * @Author: 宋爽爽
 * @Date: 2020-03-17 13:51:41
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-04-17 18:09:44
 */
/**
 * 封装 axios
 */
import Vue from 'vue';
import axios from 'axios';
import qs from 'qs';
import { getToken } from '@/util/auth';

const ajax = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_HOST_PROD : process.env.VUE_APP_HOST_DEV,
  method: 'post',
  timeout: 30000,
  // 跨域请求，携带cookie。
  withCredentials: true,
  // 服务器相应数据类型
  responseType: 'json',
  // 发送前修改数据
  transformRequest: [
    data =>
      // qs模块对data数据转换
      qs.stringify(data),
  ],
});
// 请求拦截
ajax.interceptors.request.use(
  (config) => {
    // 请求接口时展现正在加载中false 为不显示  true为显示
    config.method = 'post';
    config.headers.jepaasAuthorization = getToken() || '';
    return config;
  },
  err => Promise.reject(err),
);
// 返回拦截
ajax.interceptors.response.use(
  response => response,
  error =>
    // if (error && error.response) {
    //   errorCallback(error.response.status);
    // }
    Promise.reject(error)
  ,
);

Vue.prototype.$ajax = ajax;
ajax.install = (Vue) => {
};

export default ajax;
