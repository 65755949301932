<!--
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-04-03 09:56:21
 * @LastEditTime: 2020-05-09 09:45:19
 -->
<template>
  <div class="jepass-shop-zyzl-pay">
    <div class="padding-top-40 padding-left-30 zyVideo">
      <!-- 视频 -->
      <div
        class="prism-player height-380 margin-right-40 width-740-important"
        @click="goUrl"
      >
        <img
          v-lazy="mock.image"
          class="position-relative height-380"
        >
        <!-- <div class="playVideoRedaly height-380">
          <i class="playVideoRedalyIcon jeicon display-block jeicon-play-circle-o" />
        </div> -->
      </div>
      <!-- 价钱 -->
      <div class="height-380 position-relative">
        <div class="color-3F3F3F font-weight-600 font-size-30">
          {{ mock.payVideo.name }}
        </div>
        <div class="margin-top-20">
          <el-tag
            class="tagVideo"
            v-for="(item, _index) in mock.payVideo.labels"
            :key="_index"
            :class="_index != 0 && 'margin-left-20'"
            type="warning"
          >
            {{ item }}
          </el-tag>
        </div>
        <div class="margin-top-20 width-400">
          <div
            v-if="mock.payVideo.isVip"
            class="display-inlineBlock font-size-22 font-weight-600 color-F2A162"
          >
            VIP免费观看
          </div>
          <div
            v-if="mock.payVideo.isVip"
            class="display-inlineBlock tryJeIcon"
            :class="mock.payVideo.isVip && 'margin-left-40'"
          >
            <el-button
              class="color-8FBBD0 tryButton"
              icon="jeicon jeicon-startup-process"
              @click="goUrl"
            >
              <span
                class="font-size-16 margin-left-10 display-block"
              >试看</span>
            </el-button>
          </div>
          <div class="width-400">
            <div class="margin-top-50">
              <div
                v-if="mock.payVideo.money && mock.payVideo.money > 0"
                class="color-F9524F font-size-55"
              >
                ¥{{ mock.payVideo.money.toFixed(2) }}
              </div>
              <div
                v-if="mock.payVideo.money == 0"
                class="color-F9524F font-size-40"
              >
                免费
              </div>
            </div>
            <div
              v-if="mock.payVideo.money != 0"
              class="margin-top-20"
            >
              <div class="showVideoZy">
                <PayButtons
                  :lines="'two'"
                  :product-type="'ZYZL'"
                  @openPhoneDialog="openPhone"
                  :form-data="choosedPrice"
                />
              </div>
            </div>
          </div>
          <PhoneDialog
            ref="phoneDialog"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PayButtons from '@/pages/detail/components/payButtons';
import PhoneDialog from '@/pages/detail/components/phone';

export default {
  name: 'CommonPay',
  components: {
    PayButtons,
    PhoneDialog,
  },
  props: {
    mock: {
      type: Object,
      default() { return {}; },
    },
  },
  data() {
    return {
      videoImg: '', // 图片播放地址
      videoId: '', // 当前播放视频的ID 去获取断点播放的地址
      PlayAuth: {}, // 播放的加密信息
      videoData: [], // 当前的视频播放
      choosedPrice: {},
      shopDetails: {
        name: '',
        labels: [],
        vipStatus: false, // vip是否免费看
        money: null,
        payTypes: [],
      }, // 商品的详情信息
    };
  },
  watch: {

  },
  created() {
    this.choosedPrice.price = this.mock.payVideo.money;
    this.choosedPrice.shopCode = this.mock.shopCode;
    this.choosedPrice.payBtns = this.mock.payBtns;
  },
  methods: {
    goUrl() {
       this.$router.push({
        path: '/shopVideo',
        query: {
          code: this.mock.shopCode.toLocaleLowerCase(),
        }
      });
      // window.open(`/shop/video/${this.mock.shopCode.toLocaleLowerCase()}`);
    },
    // 打开联系电话弹窗
    openPhone() {
      this.$refs.phoneDialog.phoneDialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
@import url('../../index/style/shop/util');
.jepass-shop-zyzl-pay {
  .prism-player{
   background-color: transparent;
   cursor: pointer;
  }
  .playVideoRedaly {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.1);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .playVideoRedalyIcon {
      color: white;
      font-size: 80px;
    }
  }
}
</style>

<style lang="less">
.jepass-shop-zyzl-pay {
  // 标签的样式
  .tagVideo {
    height: 25px;
    line-height: 25px;
    padding-left: 20px;
    padding-right: 20px;
  }
  // 试看的按钮
  .tryButton {
    display: flex;
    align-items: center;
    height: 30px;
    // line-height: 22px;
    padding: 4px 10px;
  }
  .zyVideo {
    display: flex;
    flex-wrap: nowrap;
    .video {
      width: 100%;
      height: 380px;
      z-index: 1;
      object-fit: contain;
      border-radius: 20px;
      overflow: hidden;
    }
  }
  // 顶替掉支付按钮的样式
  .showVideoZy {
    .pay-btns .payBtn-two .el-button:nth-child(2n) {
      margin-left: 60px!important;
    }
    // .pay-btns .payBtn-two .el-button:nth-child(1n) {
    //   margin-left: 40px!important;
    // }
    // .pay-btns .payBtn-two .el-button:first-child {
    //   margin-left: 40px !important;
    // }
    .payBtn-two {
      text-align: left!important;
      max-width: 350px;
      // .el-button {
      //   min-width: 100px;
      //   font-size: 14px;
      //   padding: 10px 15px;
      //   margin-left: 0px;
      // }
    }
    .payBtn {
      text-align: left!important;
      .el-button {
        min-width: 90px;
        font-size: 14px;
        padding: 10px 15px;
        margin-left: 8px;
      }
    }
  }
}
</style>
