<!--
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-04-03 09:47:23
 * @LastEditTime: 2020-04-29 09:07:39
 -->
<template>
  <div class="shop-zqzj-title">
    <!-- 头部名称 -->
    <div class="title">
      {{ mock.title.name }}
    </div>
    <!-- 喇叭提示内容 -->
    <div
      class="attent"
      v-if="mock.title.hint"
    >
      <i class="jeicon jeicon-horn-o vertical-align-middle" />
      <span class="vertical-align-middle margin-left-10">{{ mock.title.hint }}</span>
    </div>
    <!-- 背景图 -->
    <div class="img margin-top-20">
      <img
        style="width: 100%"
        v-lazy="mock.title.banner ? `/jepaas/document/preview?fileKey=${mock.title.banner}` : `../assets/images/wp/wp.png`"
        alt
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mock: {
      type: Object,
      default() { return {}; },
    },
  },
};
</script>

<style lang="less">
@import url('../../index/style/shop/util');
.shop-zqzj-title {
  .el-image__error{
    min-height: 300px;
  }
  .title {
    font-size: 30px;
    font-weight: 600;
  }
  .attent {
    font-size: 20px;
    color: rgba(239, 77, 77, 1);
    margin: 20px 0 30px 0;
  }
  .img {
    margin-bottom: 20px;
    font-size: 0;
    img {
      max-height: 300px;
    }
    .el-image {
      width: 100%;
    }
    .el-image__inner {
      height: 300px;
      overflow: hidden;
    }
    .el-image__error {
      min-height: 300px;
    }
  }
}
</style>
