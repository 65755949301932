<!--
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-05-15 15:53:51
 * @LastEditTime: 2020-05-20 17:33:15
-->
<template>
  <div class="hycp-pay">
    <div class="text-status-center height-50 line-height-50">
      <span class="font-weight-600 vertical-align-middle font-hycp-pay">
        如需购买，请立即联系我们的销售人员
        <i class="jeicon jeicon-icon_msg font-hycp-icon" />
      </span>
      <el-button
        class="vertical-align-middle margin-left-40 font-size-16 hycp-pay-btn"
        type="danger"
        @click="openPhone"
        round
      >
        立即咨询
      </el-button>
    </div>
    <PhoneDialog
      ref="phoneDialog"
    />
  </div>
</template>
<script>
import PhoneDialog from '../components/phone';

export default {
  name: 'RgfwService',
  components: {
    PhoneDialog,
  },
  props: {
    mock: {
      type: Object,
      default() { return {}; },
    },
  },
  data() {
    return {
    };
  },
  methods: {
    // 打开联系电话弹窗
    openPhone() {
      this.$refs.phoneDialog.phoneDialogVisible = true;
    },
  },
};
</script>
<style lang="less" scoped>
.rgfw-pay {
  .inputNumerFlex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
</style>
<style lang="less">
@import '../../index/style/shop/util';
@keyframes blink{
    10% {
      transform:scale(1);
    }
    20% {
      transform:scale(1.2);
    }
    50% {
      transform:scale(1.3);
    }
    70% {
      transform:scale(1.2);
    }
    100% {
      transform:scale(1);
    }
  }
  .hycp-pay {
    margin: 40px auto;
    .hycp-pay-btn {
      width: 150px;
      background:linear-gradient(270deg,rgba(240,101,141,1) 0%,rgba(248,166,93,1) 100%);
      border: none;
      &:hover {
        background: none;
        background-color: #fef0f0;
        border:1px solid #F56C6C!important;
        color: #f56c6c;
      }
    }
  }
  .font-hycp-pay {
    position: relative;
    .font-hycp-icon {
      position: absolute;
      right: -25px;
      top: -30px;
      font-size: 25px;
      color: #FA5850;
      animation: blink 1s steps(3);
    }
  }
</style>
