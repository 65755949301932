import { Message } from 'element-ui';

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  const search = url.substring(url.lastIndexOf('?') + 1);
  const obj = {};
  const reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1);
    let val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  const newArray = [];
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}
/**
 * 把json数据转成参数形式
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return '';
  return cleanArray(
    Object.keys(json).map((key) => {
      if (json[key] === undefined) return '';
      return `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`;
    }),
  ).join('&');
}

/**
 * 在新页面接收参数， 并且将参数转为可用的json格式
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse(
    `{"${
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, ' ')
    }"}`,
  );
}
/**
 * 校验邮箱
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email) {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true;
  }
  return false;
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]';
  }
  return Array.isArray(arg);
}

/**
 * @name: 存储到本地
 * @Descripttion:
 * @param {type} type local / session
 * @param {key} key 存储的字段名
 * @param {obj} obj 存储的数据
 * @returns {null}
 */
export function setStorage(type, key, obj) {
  if (type == 'local') {
    window.localStorage.setItem(key, isString(obj) ? obj : JSON.stringify(obj));
  } else {
    window.sessionStorage.setItem(
      key,
      isString(obj) ? obj : JSON.stringify(obj),
    );
  }
}
/**
 * @name: 从存储取出
 * @Descripttion:
 * @param {type} type local / session
 * @param {key} key 存储的字段名
 * @returns {Object}
 */
export function getStorage(type, key) {
  let storage = '';
  if (type == 'local') {
    storage = window.localStorage.getItem(key);
  } else {
    storage = window.sessionStorage.getItem(key);
  }
  return storage;
}
/**
 * @name: 删除存储的某一值
 * @Descripttion:
 * @param {type} type local / session
 * @param {key} key 存储的字段名
 * @returns {null}
 */
export function removeStorage(type, key) {
  if (type == 'local') {
    window.localStorage.removeItem(key);
  } else {
    window.sessionStorage.removeItem(key);
  }
}
/**
 * @name: 禁止滚动条滚动
 * @Descripttion:
 * @returns {null}
 */
export function stopScroll() {
  const mo = (e) => {
    e.preventDefault();
  };
  document.body.style.overflow = 'hidden';
  document.addEventListener('touchmove', mo, false); // 禁止页面滑动
}
/**
 * @name: 开启滚动条滚动
 * @Descripttion:
 * @returns {null}
 */
export function openScroll() {
  const mo = (e) => {
    e.preventDefault();
  };
  document.body.style.overflow = ''; // 出现滚动条
  document.removeEventListener('touchmove', mo, false);
}
/**
 * @name: 清除本地缓存
 * @Descripttion:
 * @param {type}  type local / session
 * @returns {null}
 */
export function clearStorage(type) {
  if (type == 'local') {
    window.localStorage.clearStorage();
  } else {
    window.sessionStorage.clearStorage();
  }
}
/**
 * @name: 后台接口异常回调
 * @Descripttion:
 * @param {code}  code 错误码
 * @returns {null}
 */
export function errorCallback(code) {
  let msg = '请求异常，请联系开发人员';
  switch (code) {
    case 400:
      msg = '请求出错(400)';
      break;
    case 401:
      msg = '未授权，请重新登录(401)';
      break;
    case 403:
      msg = '拒绝访问(403)';
      break;
    case 404:
      msg = '请求错误(404)';
      break;
    case 408:
      msg = '请求超时(408)';
      break;
    case 500:
      msg = '服务器错误(500)';
      break;
    case 501:
      msg = '服务未实现(501)';
      break;
    case 502:
      msg = '网络错误(502)';
      break;
    case 503:
      msg = '服务不可用(503)';
      break;
    case 504:
      msg = '网络超时(504)';
      break;
    case 505:
      msg = 'HTTP版本不受支持(505)';
      break;
    case '100001':
      msg = '存在待支付订单，请前往订单处理';
      break;
    case '100002':
      msg = '您当前额度不足以支付此次订单金额，请联系销售人员协商处理！';
      break;
    case '5101':
      msg = '用户使用微信进行余额充值失败!';
      break;
    case '9102':
      msg = '该手机号未注册请先前往注册!';
      break;
    default:
      break;
  }
  Message({
    type: 'error',
    message: msg,
    duration: 5000,
  });
}
/**
 * @name: 获取页面url
 * @Descripttion:
 * @returns {String}
 */
export function getPageUrl() {
  return window.location.pathname.substring(1);
}
/**
 * @name: 倒计时
 * @Descripttion:
 * @param {string}  startTime 倒计时开始的时间
 * @param {number}  duration 倒计时多长时间 单位分钟
 * @param {string}  pastText 倒计时结束提示文字
 * @param {Boolean} clearTimer 是否先清掉定时器
 * @param {string}  type  hour返回时分秒（1-24小时内的定时） minute返回分秒（1-60分钟的定时） second返回秒（一分钟内的定时）
 * @param {function}  callback 参数(剩余时间 或者 结束提示)
 * @returns {string}
 */
const runBack = (cm, type, cb) => {
  let min = parseInt(cm / 60);
  min = min > 9 ? min : `0${min}`;
  let sec = (cm % 60).toFixed(0);
  sec = sec > 9 ? sec : `0${sec}`;
  if (type == 'minute') {
    return cb(`${min} 分 ${sec} 秒`);
  } if (type == 'second') {
    return cb(`${sec} 秒`);
  }
};
// 定义全局定时器
window.JEwechatpaytimers = {
  hour: null,
  minute: null,
  second: null,
};
export function computeTime(startTime, duration, pastText, clearTimer, type, callback) {
  if (clearTimer && type) {
    clearInterval(window.JEwechatpaytimers[type]);
    window.JEwechatpaytimers[type] = null;
  }
  const ct = startTime.replace(/\-/g, '/'); // 创建订单时间
  const ts = new Date().getTime();
  const tc = new Date(ct).getTime();
  let cm = (duration * 60 * 1000 - (ts - tc)) / 1000;
  window.JEwechatpaytimers[type] = setInterval(() => {
    cm -= 1;
    runBack(cm, type, callback);
    if (cm < 1) {
      clearInterval(window.JEwechatpaytimers[type]);
      return callback(pastText || '已过期');
    }
  }, 1000);
}
/**
 * @name: 数组去重
 * @Descripttion:
 * @param {Array}  array
 * @param {string}  field
 * @returns {}
 */
export function dedupe(array, field) {
  const res = new Map();
  return array.filter(a => !res.has(a[field]) && res.set(a[field], 1));
}

/**
 * @name: 两个对象是否相等  !!!!! 注意只能判断一层的对象是否相等，如果嵌套多层对象需要添加递归方法
 * @Descripttion: 用Object.getOwnPropertyNames拿到对象的所以键名数组
 * 1.用Object.getOwnPropertyNames拿到对象的所以键名数组
 * 2.比对键名数组的长度是否相等。否=>false。真=>3
 * 3.比对键名对应的键值是否相等
 * @param {type}
 * @returns:
 */
export function isObjectValueEqual(obj1, obj2) {
  let aProps = Object.getOwnPropertyNames(obj1);
  let bProps = Object.getOwnPropertyNames(obj2);
  aProps = aProps.filter(ap => ap != '__ob__'); // 过滤掉vue数据双向绑定的obseverKey的key键占位问题
  bProps = bProps.filter(bp => bp != '__ob__'); // 过滤掉vue数据双向绑定的obseverKey的key键占位问题
  if (aProps.length != bProps.length) {
    return false;
  }

  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i];
    const propA = obj1[propName];
    const propB = obj2[propName];
    if (propA !== propB) {
      return false;
    }
  }
  return true;
}
/**
 * @name: 数组排序
 * @Descripttion:
 * @param {Array}  array
 * @param {string}  field 根据哪个字段排序
 * @returns {}
 */
export function arraySort(array, field) {
  array.sort((a, b) => {
    if (a[field] < b[field]) {
      return -1;
    }
    if (a[field] > b[field]) {
      return 1;
    }
    return 0;
  });
  return array;
}
/**
 * @name: 清除字符串所有空格
 * @Descripttion:
 * @param {string}  str
 * @returns {}
 */
export function clearSpace(str) {
  return str.replace(/\s/g, '');
}
/**
 * @name: 验证非法字符
 * @Descripttion: 校验  ！@#￥%&*$。？‘’“”'"；：:;/+
 * @param {string}  str
 * @returns {}
 */
export function checkCharacter(str) {
  const regExp = /[@#￥。？‘’“”'"；;/+\$%\^&\*]+/g;
  return regExp.test(str);
}
/**
 * @name: 验证纯数字
 * @Descripttion:
 * @param {string}  str
 * @returns {}
 */
export function checkNumber(str) {
  const regExp = /^[0-9]{1,20}$/;
  return regExp.test(str);
}
/**
 * @name: 验证小数
 * @Descripttion:
 * @param {string}  str
 * @returns {}
 */
export function checkFloat(str) {
  return isNaN(Number(str));
}
/**
 * @name: 给元素增加动画
 * @Descripttion:  结合animate.css 实现动画效果
 * @param {dom}  element
 * @param {string}  animationName 动画classname
 * @param {string}  delay 动画延时的classname
 * @param {function}  callback
 * @returns {}
 */
export function animateCSS(element, animationName, delay, callback) {
  if (!delay) {
    delay = 'fast';
  }
  const node = document.querySelector(element);
  node.classList.add('animated', animationName, delay);

  function handleAnimationEnd() {
    node.classList.remove('animated', animationName, delay);
    node.removeEventListener('animationend', handleAnimationEnd);

    if (typeof callback === 'function') callback();
  }

  node.addEventListener('animationend', handleAnimationEnd);
}

// 根据尾缀来确定icon
export function fileSuffixIcon(suffix) {
  //  先将尾缀统一变成小写
  const unKnow = {
    icon: 'jeicon-unknownfile',
    color: '#A9ABA9',
  };
  const allIcons = [
    {
      icon: 'jeicon-square-flash',
      suffixs: ['swf', 'rm', 'mov', 'swf', 'fla', 'flash'],
      color: '#46DDA2',
    }, // flash 的文件
    {
      icon: 'jeicon-jepg',
      suffixs: ['jpeg'],
      color: '#A3DB73',
      isImg: true, // 是否是图片
    }, // jepg 的文件
    {
      icon: 'jeicon-js',
      suffixs: ['js', 'json', 'script', 'javascript'],
      color: '#F4CE4B',
    }, // js 文件
    {
      icon: 'jeicon-ie',
      suffixs: ['ie', 'IE'],
      color: '#F8A646',
    }, // ie 的文件
    {
      icon: 'jeicon-svg',
      suffixs: ['svg'],
      isImg: true, // 是否是图片
      color: 'rgb(255, 119, 68)',
    }, // svg 的文件
    {
      icon: 'jeicon-square-gif',
      suffixs: ['gif'],
      isImg: true, // 是否是图片
      color: '#A3DB73',
    }, // gif 的文件
    {
      icon: 'jeicon-audio',
      suffixs: ['mp4', 'mp3'],
      color: '#F65A59',
    }, // 音频文件
    {
      icon: 'jeicon-mp3',
      suffixs: ['mp3', 'wma', 'wav', 'ape', 'mid'],
      color: '#F65A59',
    }, // mp3文件
    {
      icon: 'jeicon-exe',
      suffixs: ['exe'],
      color: '#4BB8F3',
    }, // exe文件
    {
      icon: 'jeicon-rar',
      suffixs: ['rar'],
      color: '#FBAF32',
    }, // rar文件
    {
      icon: 'jeicon-psd',
      suffixs: ['psd'],
      color: '#4BB8F3',
    }, // psd文件
    {
      icon: 'jeicon-bmp',
      suffixs: ['bmp'],
      isImg: true, // 是否是图片
      color: '#D088E1',
    }, // bmp文件
    {
      icon: 'jeicon-png',
      suffixs: ['png'],
      isImg: true, // 是否是图片
      color: '#4BB8F3',
    }, // png文件
    {
      icon: 'jeicon-jpg',
      suffixs: ['jpg'],
      isImg: true, // 是否是图片
      color: 'rgb(255, 119, 68)',
    }, // jpg文件
    {
      icon: 'jeicon-html',
      suffixs: ['html'],
      color: '#6DCC51',
    }, // html文件
    {
      icon: 'jeicon-dwf',
      suffixs: ['dwf'],
      color: '#6DCC51',
    }, // dwf文件
    {
      icon: 'jeicon-zip',
      suffixs: ['zip', '7-zip', '7z', 'winzip'],
      color: '#FBAF32',
    }, // zip文件
    {
      icon: 'jeicon-pdf',
      suffixs: ['pdf'],
      color: '#E52C0E',
    }, // pdf文件
    {
      icon: 'jeicon-ppt',
      suffixs: ['pptx', 'pptm', 'ppt', 'potx', 'potm', 'pot', 'ppsx', 'ppsm', 'xml'],
      color: '#F34E19',
    }, // ppt文件
    {
      icon: 'jeicon-txt',
      suffixs: ['txt'],
      color: '#2BA244',
    }, // ppt文件
    {
      icon: 'jeicon-excel',
      suffixs: ['xls', 'xlsx', 'xlsm'],
      color: '#00843E',
    }, // ppt文件
    {
      icon: 'jeicon-word',
      suffixs: ['doc', 'docx', 'dot', 'dotx', 'docm'],
      color: '#0057AA',
    }, // ppt文件
  ];
  if (!suffix) {
    return {
      icon: 'jeicon-folders',
      color: '#F7C12D',
    };
  }
  const lowSuffix = suffix.toLowerCase();
  let suffixObj = [];
  suffixObj = allIcons.filter((suff) => {
    if (suff.suffixs.includes(lowSuffix)) {
      return suff;
    }
  });
  suffixObj.length == 0 && (suffixObj.push(unKnow));
  return suffixObj[0];
}
