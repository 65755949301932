<!--
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-04-03 10:37:32
 * @LastEditTime: 2020-05-25 11:01:32
 -->
<template>
  <!-- 下方切换的动态公共组件 -->
  <div class="jepass-shop-rgfw-tabs">
    <!-- 产品说明tabs部分 -->
    <el-tabs
      v-model="activeName"
      class="explains"
    >
      <el-tab-pane
        label="产品详情"
        name="CPXQ"
      >
        <div>
          <div
            class="font-size-20"
            v-for="(info, index) in mock.info"
            :key="index"
          >
            <div
              class="margin-top-20"
              v-if="info.type == 'text'"
            >
              <div
                class="font-weight-600 padding-left-20 border-left-6-solid-F9524F line-height-50 height-50"
                v-if="info.content.title"
              >
                {{ info.content.title }}
              </div>
              <div
                class="padding-left-40 margin-top-20"
                v-if="info.content.main"
              >
                {{ info.content.main }}
              </div>
              <!-- 关联更多显示 -->
              <div
                v-if="info.content.titleUrl"
                class="margin-top-20 font-size-18"
              >
                关联更多: <span
                  class="font-size-16 cursor-pointer-hover"
                  style="color: rgb(64,158,255)"
                  @click="goUrl(info.content.url)"
                >{{ info.content.titleUrl }}</span>
              </div>
            </div>
            <!-- 图片显示 -->
            <div
              class="margin-top-20"
              v-if="info.type == 'img'"
            >
              <div
                class="font-weight-600 padding-left-20 border-left-6-solid-F9524F line-height-50 height-50"
                v-if="info.content.title"
              >
                {{ info.content.title }}
              </div>
              <!-- 如果存在图片的话进行循环显示 -->
              <div v-if="info.content.main">
                <div
                  v-for="(img, _img) in info.content.main"
                  :key="_img"
                  class="margin-top-20"
                >
                  <img
                    style="width: 100%"
                    v-lazy="img.path ? `/jepaas/document/preview?fileKey=${img.path}` : ``"
                    alt
                  >
                </div>
              </div>
              <!-- 关联更多显示 -->
              <div
                v-if="info.content.titleUrl"
                class="margin-top-20 font-size-18"
              >
                关联更多: <span
                  class="font-size-16 cursor-pointer-hover"
                  style="color: rgb(64,158,255)"
                  @click="goUrl(info.content.url)"
                >{{ info.content.titleUrl }}</span>
              </div>
            </div>
            <!-- 图文显示 -->
            <div
              class="margin-top-20"
              v-if="info.type == 'html'"
            >
              <div
                class="font-weight-600 padding-left-20 border-left-6-solid-F9524F line-height-50 height-50"
                v-if="info.content.title"
              >
                {{ info.content.title }}
              </div>
              <div
                class="padding-left-40 margin-top-20"
                v-if="info.content.main"
              >
                <div
                  class="html_img"
                  v-html="info.content.main"
                />
              </div>
            </div>
            <!-- 视频显示 -->
            <div
              class="margin-top-20"
              v-if="info.type == 'video'"
            >
              <div
                class="font-weight-600 padding-left-20 border-left-6-solid-F9524F line-height-50 height-50"
                v-if="info.content.title"
              >
                {{ info.content.title }}
              </div>
              <div
                class="padding-left-40 margin-top-20"
                v-if="info.content.main"
              >
                <div
                  :id="`${info.content.vidId}`"
                  ref="player"
                  class="prism-player video_con"
                />
              </div>
              <!-- 关联更多显示 -->
              <div
                v-if="info.content.titleUrl"
                class="margin-top-20 font-size-18"
              >
                关联更多: <span
                  class="font-size-16 cursor-pointer-hover"
                  style="color: rgb(64,158,255)"
                  @click="goUrl(info.content.url)"
                >{{ info.content.titleUrl }}</span>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="产品规格"
        name="SYSM"
      >
        <div>
          <div class="font-size-18 color-A9ABA9 min-height-500">
            <div
              v-for="(item, _item) in mock.attr"
              :key="_item"
              :class="_item > 0 && 'padding-top-30'"
              class="border-bottom-2-solid-F2F2F2 padding-left-30 padding-bottom-30"
            >
              <el-row :gutter="20">
                <el-col
                  :span="3"
                  :offset="1"
                >
                  <div>
                    {{ item.key }}
                  </div>
                </el-col>
                <el-col
                  :span="6"
                  :offset="1"
                >
                  <div>
                    {{ item.content }}
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: 'CommonTabs',
  props: {
    mock: {
      type: Object,
      default: -1,
    },
  },
  data() {
    return {
      activeName: 'CPXQ',
    };
  },
  methods: {
    goUrl(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="less" scoped>
@import url('../../index/style/shop/util');
</style>
<style lang="less">
.jepass-shop-rgfw-tabs {
  ol {
    margin: 0!important
  }
  p {
   margin: 0 !important;
 }
  .el-image__error{
    min-height: 300px;
  }
  .html_img img{
    width: 100%;
    height: auto;
  }
}
</style>
