<!--
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-04-03 09:47:23
 * @LastEditTime: 2020-04-27 09:35:04
 -->
<template>
  <div class="shop-common-title">
    <!-- 喇叭提示内容 -->
    <div
      v-if="mock.title.hint"
      class="padding-left-30 trumpet-background trumpet-color height-50 line-height-50"
    >
      <i class="jeicon jeicon-horn-o vertical-align-middle font-size-20" />
      <span
        class="vertical-align-middle font-size-18 margin-left-5"
      >{{ mock.title.hint }}</span>
    </div>
    <!-- 背景图 -->
    <div
      v-if="mock.title.banner"
      class="img"
    >
      <img
        style="width: 100%"
        v-lazy="mock.title.banner ? `/jepaas/document/preview?fileKey=${mock.title.banner}` : `../assets/images/wp/wp.png`"
        alt
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mock: {
      type: Object,
      default: -1,
    },
  },
};
</script>

<style lang="less" scoped>
@import url('../../index/style/shop/util');
.shop-common-title {
  .el-image__inner {
      height: 300px;
      overflow: hidden;
    }
  .el-image__error{
    min-height: 300px;
  }
  .title {
    font-size: 30px;
    font-weight: 600;
  }
  .attent {
    font-size: 20px;
    color: rgba(239, 77, 77, 1);
    margin: 20px 0 30px 0;
  }
  .img {
    margin-bottom: 20px;
    max-height: 300px;
    font-size: 0;
  }
}
</style>
