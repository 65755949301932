<template>
  <div class="rgfw-pay">
    <div class="btns clear-float">
      <div class="float-left">
        <span class="color-F84E4E font-size-30 font-weight-600">
          ¥{{ num_price }}.00 / <span class="font-size-24">单价:{{ unit_price }}元</span>
        </span>
        <!-- <span class="color-F84E4E font-size-30 font-weight-600"></span> -->
        <div class="margin-left-20 display-inlineBlock">
          <div class="inputNumerFlex">
            <div>
              <el-button
                :disabled="disabledMinus"
                @click="countNumber('minus')"
              >
                -
              </el-button>
            </div>
            <div class="margin-left-10 margin-right-10">
              <el-input
                @input="numberChange"
                maxlength="7"
                :min="1"
                v-model="num_rgfw"
              />
            </div>
            <div>
              <el-button @click="countNumber('add')">
                +
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <span class="btn">
        <PayButtons
          :lines="'one'"
          :product-type="'DEFAULT'"
          :form-data="choosedPrice"
          @openPhoneDialog="openPhone"
        />
      </span>
    </div>
    <PhoneDialog
      ref="phoneDialog"
    />
  </div>
</template>
<script>
import { getResourceData } from '@/pages/detail/actions/actions';
import PayButtons from '../components/payButtons';
import PhoneDialog from '../components/phone';

export default {
  name: 'RgfwService',
  components: {
    PayButtons,
    PhoneDialog,
  },
  props: {
    isAtTop: {
      type: Number,
      default: 0, // 0是不显示 1 是显示在金额上面 2是显示在金额后面
    },
    price: {
      type: Number,
      default: 0,
    },
    radioRules: {
      type: Array,
      default() {
        return [];
      },
    },
    mock: {
      type: Object,
      default() { return {}; },
    },
  },
  data() {
    return {
      disabledMinus: true, // 知否禁用减号
      num_price: 0, // 总价
      unit_price: 0, // 单价
      num_rgfw: 1, // 人工服务的数量选择
      choosedPrice: {},
      radioArrData: [], // 当前价格体系
      defaultSelected: {},
    };
  },
  created() {
    this.getConfig();
  },
  // computed: {
  //   _defaultSelected:{
  //     get(){
  //       return this.radioRules[0];
  //     },
  //     set(val) {
  //       this.defaultSelected = val;
  //     }
  //   },
  // },
  methods: {
    // 获取组件配置信息
    getConfig() {
      this.choosedPrice.shopCode = this.mock.shopCode;
      this.choosedPrice.payBtns = this.mock.payBtns;
      const params = {
        productCode: this.mock.shopCode.toLowerCase(),
        type: 'labour',
      };
      getResourceData(params).then((res) => {
        if (res.success && res.obj.length > 0) {
          this.unit_price = res.obj[0].values.RGFW_JG;
          this.choosedPrice.shopId = res.obj[0].values.JEPAAS_RGFW_ID;
          this.handleChange(1);
        }
      });
    },
    // 点击加减号
    countNumber(status) {
      this.num_rgfw = parseInt(this.num_rgfw);
      if (this.num_rgfw >= 9999999 && status == 'add') {
        return false;
      }
      if (status == 'add') {
        this.disabledMinus = false;
        this.num_rgfw += 1;
      } else {
        this.num_rgfw > 1 && (this.num_rgfw -= 1) && (this.disabledMinus = false);
      }
      this.num_rgfw <= 1 && (this.disabledMinus = true); // 如果等于1的话禁止点击减号
      this.numberChange(this.num_rgfw);
    },
    // 金额数据改变
    numberChange(data) {
      // debugger;
      const val = parseInt(data);
      const reg = new RegExp('^[0-9]*$');
      if (!val || val == 0 || !reg.test(val)) {
        this.num_rgfw = 1;
      } else {
        this.num_rgfw = val;
      }
      // debugger;
      this.num_rgfw <= 1 && (this.disabledMinus = true) || (this.disabledMinus = false); // 如果等于1的话禁止点击减号
      this.num_price = this.num_rgfw * this.unit_price; // 总价格
      this.choosedPrice.price = this.num_price;
      this.choosedPrice.count = this.num_rgfw;
    },
    // 人工服务的计数器的选择事件
    handleChange(val) {
      this.num_rgfw = val;
      this.num_price = val * this.unit_price; // 总价格
      this.choosedPrice.price = this.num_price;
      this.choosedPrice.count = val;
    },
    // 打开联系电话弹窗
    openPhone() {
      this.$refs.phoneDialog.phoneDialogVisible = true;
    },
  },
};
</script>
<style lang="less" scoped>
.rgfw-pay {
  .inputNumerFlex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
</style>
<style lang="less">
@import '../../index/style/shop/util';
.rgfw-pay {
  .inputNumerFlex {
    .el-button {
      padding: 5px 15px;
      font-size: 25px;
    }
    .el-input__inner {
      width: 90px;
      padding: 0 5px;
      font-size: 18px;
      text-align: center;
    }
  }
  .el-radio-group {
    width: 100%;
    .el-radio-button {
      width: 100%;
      &:last-child {
        margin-right: 0;
      }
    }
    .el-radio-button__inner {
      border: none;
      background: #f5f5f5;
      height: 44px;
      line-height: 25px;
      border: 0 !important;
      /*border: 1px solid rgba(247, 74, 73, 1);*/
      /*border-left: 1px solid rgba(247, 74, 73, 1);*/
      width: 100%;
      box-sizing: border-box;
      border-radius: 3px;
      font-weight: 500;
      margin-right: 40px;
    }
    .el-radio-button:first-child .el-radio-button__inner {
      border-radius: 3px;
      /*border-left: 1px solid rgba(247, 74, 73, 1);*/
    }
    .el-radio-button:last-child .el-radio-button__inner {
      margin-right: 0;
      border-radius: 3px;
    }
    .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      border: 0;
      background: linear-gradient(
        270deg,
        rgba(253, 147, 81, 1) 0%,
        rgba(247, 73, 73, 1) 100%
      );
      box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.15);
      /*border-color: rgba(253, 147, 81, 1);*/
    }
  }
}
</style>
