import { render, staticRenderFns } from "./FloatBut.vue?vue&type=template&id=28aea694&scoped=true&"
import script from "./FloatBut.vue?vue&type=script&lang=js&"
export * from "./FloatBut.vue?vue&type=script&lang=js&"
import style0 from "./FloatBut.vue?vue&type=style&index=0&id=28aea694&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28aea694",
  null
  
)

export default component.exports