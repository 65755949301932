<template>
  <div class="float_btn_wechart">
    <p>扫码关注公众号</p>
    <img :src="qr_qrcode" alt="" />
  </div>
</template>

<script>
import qr_qrcode from '../assets/imgs/wx_qrcode.png'

export default {
  name: 'FloatBtnWeChart',
  data() {
    return {
      qr_qrcode
    }
  }
}
</script>

<style lang="less" scoped>
.float_btn_wechart {
  padding: 20px;
  > p {
    margin: 0;
    color: #333333;
    font-size: 18px;
    text-align: center;
  }
  > img {
    width: 140px;
    height: 140px;
    margin-top: 20px;
  }
}
</style>
