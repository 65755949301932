<!--
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-04-03 09:56:21
 * @LastEditTime: 2020-05-19 15:46:18
 -->
<template>
  <div class="jepass-shop-zqzj-pay">
    <div class="handlearea">
      <div class="ddown">
        <i class="handleIcon el-icon-arrow-down" />
        <el-dropdown
          trigger="click"
          @command="handleCommand"
        >
          <span class="el-dropdown-link">
            <i
              v-if="choosedPrice.isRecommended"
              class="recommend jeicon jeicon-recommended"
              style="top: -5px;"
            />
            <div class="pric">
              ￥{{ choosedPrice.price && choosedPrice.price.toFixed(2) }}
              <i
                v-if="choosedPrice.isHot"
                class="hot jeicon jeicon-hot"
              />
            </div>
            <div class="cfg">{{ choosedPrice.sm || mock.title.name }}</div>
          </span>
          <el-dropdown-menu
            class="zqzjDropdown"
            slot="dropdown"
          >
            <el-dropdown-item
              v-for="(item, index) in wpData.dropdown"
              :key="index"
              :command="item"
              :style="{
                background: item.configurationId == choosedPrice.configurationId ? '#FC6251' : ''
              }"
            >
              <div
                class="pric"
                :style="{
                  color: item.configurationId == choosedPrice.configurationId ? '#fff' : ''
                }"
              >
                ￥{{ item.price && item.price.toFixed(2) }}
                <i
                  v-if="item.isHot"
                  :style="{
                    color: item.configurationId == choosedPrice.configurationId ? '#fff' : ''
                  }"
                  class="hot jeicon jeicon-hot"
                />
                <i
                  v-if="item.isRecommended"
                  :style="{
                    color: item.configurationId == choosedPrice.configurationId ? '#fff' : ''
                  }"
                  class="recommended-on jeicon jeicon-recommended-on"
                />
              </div>
              <div
                class="cfg"
                :style="{
                  color: item.configurationId == choosedPrice.configurationId ? '#fff' : ''
                }"
              >
                {{ item.sm || mock.title.name }}
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="rpay">
        <div
          v-if="!hasLogin"
          class="display-inline font-size-24"
        >
          <span>请您先前往</span>
          <span
            class="color-F9524F cursor-pointer-hover"
            @click="goLogin"
          >登录</span>
        </div>
        <div
          v-if="hasLogin && wpData.zs.length == 0"
          class="display-inline font-size-24"
        >
          <span>请您先前往</span>
          <span
            class="color-F9524F cursor-pointer-hover"
            @click="goBuyZs"
          >购买证书</span>
        </div>
        <div
          v-if="hasLogin && wpData.zs.length > 0"
          class="zsselect"
        >
          <label class="font-weight-600">选择平台商业证书：</label>
          <el-select
            v-model="zsNumber"
            :disabled="!hasLogin"
            placeholder="请选择"
            @change="selectChange"
          >
            <el-option
              v-for="(item, index) in wpData.zs"
              :key="index"
              :label="item.values.DDGL_GLZS"
              :value="item.values.DDGL_GLZS"
            />
          </el-select>
        </div>
        <div class="btns">
          <PayButtons
            :lines="'one'"
            :product-type="'ZQZJ'"
            :form-data="choosedPrice"
            :copyrights="wpData.zs.length"
            @openPhoneDialog="openPhone"
            @zsNumberShow="zsNumberShow"
          />
        </div>
        <!-- 未拥有商业证书提示 -->
        <el-dialog
          :visible.sync="PayZs"
          width="558px"
          custom-class="pay-tips"
          :close-on-click-modal="false"
        >
          <div
            slot="title"
            class="font-size-20 font-weight-600 text-status-left"
          >
            提示
          </div>
          <div class="tip-word font-size-20">
            您还未拥有可购买组件的商业证书，请您前往购买？
          </div>
          <span
            slot="footer"
            class="dialog-footer"
          >
            <el-button
              size="small"
              type="danger"
              @click="goPayZs"
            >前往</el-button>
            <!-- <el-button
              size="small"
              @click="PayZs = false"
            >取消</el-button> -->
          </span>
        </el-dialog>
        <PhoneDialog
          ref="phoneDialog"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PayButtons from '@/pages/detail/components/payButtons';
import PhoneDialog from '@/pages/detail/components/phone';
import { isArray } from '@/util/util';
import { getToken } from '@/util/auth';
import {
  getCmpConfig,
  loadCertificateByUserId,
  getDsCheckPlugin,
} from '@/pages/detail/actions/actions';

export default {
  name: 'CommonPay',
  components: {
    PayButtons,
    PhoneDialog,
  },
  props: {
    mock: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      PayZs: false, // 未拥有商业证书
      activeName: 'CPXQ',
      isLoginOrRegister: '商城',
      isRoutePage: false, // 是否走路由
      zsNumber: '',
      payZQZJStatus: false, // 是否显示该证书是否允许购买
      hasLogin: false, // 登录为true
      choosedPrice: {},
      wpData: {
        title: 'JE网盘',
        dropdown: [],
        zs: [],
      },
    };
  },
  watch: {
    '$store.state.loginStatus': function (nv) {
      this.hasLogin = nv;
      this.getConfig();
      if (nv) {
        this.getZsList();
      }
    },
  },
  created() {
    this.getConfig();
  },
  mounted() {
    // 在页面加载时读取localStorage里的状态信息
    if (getToken()) {
      this.hasLogin = true;
      // 如果有用户信息  则请求配置信息
      this.getZsList();
    }
  },
  methods: {
    // 判断当前证书是否购买过组件
    getIsPlugin() {
      getDsCheckPlugin({ copyrightBh: this.zsNumber, pluginCode: this.mock.pluginCode }).then((result) => {
        if (!result.success) {
          this.choosedPrice.payZQZJStatus = true;
        } else {
          this.choosedPrice.payZQZJStatus = false;
        }
      });
    },
    // 显示商业证书未购买提示
    zsNumberShow() {
      this.PayZs = true;
    },
    // 前往购买商业证书
    goPayZs() {
      this.goBuyZs();
    },
    // 打开联系电话弹窗
    openPhone() {
      this.$refs.phoneDialog.phoneDialogVisible = true;
    },
    // 前往登录页
    goLogin() {
      this.$router.push({
        path: '/login',
      });
    },
    // 前往购买正书
    goBuyZs() {
      window.open(`/shop/${window.__lceCode}.html`);
    },
    // 选择价格
    handleCommand(command) {
      this.choosedPrice = command;
      this.choosedPrice.shopId = command.values.JEPAAS_SPZJPZ_ID;
      this.choosedPrice.zsNumber = this.zsNumber;
    },
    // 获取组件配置信息
    getConfig() {
      const code_ = this.mock.shopCode;
      this.choosedPrice.payBtns = this.mock.payBtns;
      getCmpConfig({ productCode: code_ }).then((res) => {
        if (res.success) {
          this.wpData.dropdown = res.obj;
          if (isArray(this.wpData.dropdown)) {
            this.formatData(this.wpData.dropdown);
          } else {
            this.wpData.dropdown = [];
          }
        }
      });
    },
    // 格式化数据
    formatData(arr) {
      arr.forEach((item) => {
        item.isHot = item.values.SPZJPZ_SFHOT_CODE == '1' || false;
        item.isRecommended = item.values.SPZJPZ_SFTJ_CODE == '1' || false;
        item.price = item.values.SPZJPZ_JG;
        item.shopCode = this.mock.shopCode;
        item.configurationId = item.pkValue;
        item.sm = item.values.SPZJPZ_PZXX;
      });
      // 默认展示推荐的第一条
      this.choosedPrice = arr.filter(
        item => item.values.SPZJPZ_SFTJ_CODE == '1',
      )[0];
      // 如果没有推荐   默认展示第一条
      if (!this.choosedPrice) {
        this.choosedPrice = arr[0];
      }
      this.choosedPrice.pluginCode = this.mock.pluginCode;
      this.choosedPrice.shopId = this.choosedPrice.values.JEPAAS_SPZJPZ_ID; // 默认第一条的ID
      this.choosedPrice.zsNumber = this.zsNumber;
    },
    // 获取商业证书信息
    getZsList() {
      loadCertificateByUserId().then((res) => {
        if (res.success) {
          this.wpData.zs = isArray(res.obj)
            ? res.obj.filter(item => item.values.DDGL_GLZS)
            : [];
          if (this.wpData.zs.length > 0) {
            this.selectChange(this.wpData.zs[0].values.DDGL_GLZS);
          }
        }
      });
    },
    // 选择证书
    selectChange(value) {
      this.choosedPrice.zsNumber = value;
      this.zsNumber = value;
      this.getIsPlugin();
    },
  },
};
</script>

<style lang="less">
@import url('../../index/style/shop/util');
.jepass-shop-zqzj-pay {
  .handlearea {
    display: flex;
    .ddown {
      width: 625px;
      height: 110px;
      border-radius: 4px;
      border: 1px solid rgba(216, 216, 216, 1);
      position: relative;
      box-sizing: border-box;
      .el-dropdown {
        width: 100%;
        height: 100%;
        padding: 10px 40px;
        box-sizing: border-box;
        .el-dropdown-link {
          display: inline-block;
          cursor: pointer;
          width: 100%;
          height: 100%;
          .recommend {
            position: absolute;
            right: 0;
            color: #ee9a00;
            font-size: 70px;
          }
        }
      }
      .pric {
        font-size: 36px;
        font-weight: 600;
        color: rgba(249, 82, 79, 1);
        .jeicon {
          font-size: 36px;
          font-weight: normal;
        }
      }
      .cfg {
        font-size: 18px;
        color: rgba(169, 171, 169, 1);
        margin-top: 10px;
      }
      .handleIcon {
        position: absolute;
        top: 50%;
        right: 40px;
        transform: translateY(-50%);
      }
    }
    .rpay {
      flex: 1;
      text-align: right;
      .btns {
        padding-top: 28px;
        .payBtn {
          .el-button + .el-button {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
.zqzjDropdown {
  padding: 0 !important;
  border: 0 !important;
  overflow: hidden;
  .pric {
    font-size: 30px;
    font-weight: 500;
    color: #3f3f3f;
    padding-top: 10px;
    position: relative;
    .hot {
      color: #fe2222;
      font-size: 36px;
    }
    .recommended-on {
      color: #ee9a00;
      font-size: 36px;
    }
  }
  .cfg {
    color: rgba(169, 171, 169, 1);
    font-size:20px;
    width: 585px;
    padding: 5px 0;
    border-bottom: 1px solid #f5f5f5;
    &:last-child {
      border-bottom: 0;
    }
  }
}
</style>
