<template>
  <div
    id="je-common-detail"
    class="Jepaas-common-detail"
  >
    <el-backtop />
    <div v-show="isRoutePage">
      <router-view />
    </div>
    <div v-show="!isRoutePage">
      <div class="box_top">
        <Header :is-login-or-register="isLoginOrRegister" />
      </div>
      <div class="je-detail-content">
        <!-- 头部标题和banner的内容 -->
        <Title :mock="MOCK_show" />
        <!-- 中间支付的公共组件 -->
        <commonPay
          :mock="MOCK_show"
        />
        <!-- 底部内容tab切换 -->
        <commonTabs
          @upgradeVip="upgradeVip"
          :mock="MOCK_show"
        />
        <!-- 购买的弹出框 -->
        <div class="showVideoZy">
          <payVideo
            :video-details="vipVideo"
            :show-video="showVideo"
            @closePayVideo="closePayVideo"
          />
        </div>
      </div>
    </div>

    <OldFloatBut />
    <FloatBut />
  </div>
</template>

<script>
import Header from '@/components/Header';
import OldFloatBut from '@/components/FloatBut';
import FloatBut from '@/components/newFloatBtn';

import { getToken } from '@/util/auth';
import { getStorage } from '@/util/util';
import MOCK from '@/pages/detail/zyzl/filterMock';
// 公共组件
import Title from '@/pages/detail/zyzl/commonTitle';
import commonPay from '@/pages/detail/zyzl/commonPay';
import commonTabs from '@/pages/detail/zyzl/commonTabs';

import payVideo from '../components/payVideo';

export default {
  name: 'CommonDetails',
  components: {
    FloatBut,
    OldFloatBut,
    Header,
    Title,
    commonPay,
    commonTabs,
    payVideo,
  },
  data() {
    return {
      vipVideo: {},
      showVideo: false,
      activeName: 'CPXQ',
      MOCK_show: MOCK,
      isLoginOrRegister: '商城',
      isRoutePage: false, // 是否走路由
      zsNumber: '',
      choosedPrice: {},
      wpData: {
        title: 'JE网盘',
        dropdown: [],
        zs: [],
      },
      persons: [], // 客服电话
    };
  },
  watch: {
    $route(nv) {
      if (!nv.name) {
        this.isRoutePage = false;
      } else {
        this.isRoutePage = true;
      }
    },
  },
  created() {
    // getResourceData({ type: 'resource', productCode: this.MOCK_show.shopCode }).then((res) => {
    //   if (res.success) {
    //     const datas = res.rows || res.obj || [];
    //     this.radioArrData = datas;
    //     // this.defaultSelected = this.radioArrData[0];
    //   }
    // });
  },
  mounted() {
    // 在页面加载时读取localStorage里的状态信息
    if (getToken()) {
      if (getStorage('local', 'jepaasStore')) {
        this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(getStorage('local', 'jepaasStore'))));
      }
    }
  },
  methods: {
    // 前往登录页
    goLogin() {
      this.$router.push({
        path: '/login',
      });
    },
    // 前往购买正书
    goBuyZs() {
      window.open('syzs_1.html');
    },
    openShowVideo() {
      this.showVideo = true;
    },
    closePayVideo() {
      this.showVideo = false;
    },
    // 升级Vip
    upgradeVip(data) {
      this.vipVideo = {
        title: data.name || '没有名称的视频',
        money: '9699.00', // 视频价格
      };
      this.openShowVideo();
    },
  },
};
</script>
<style lang="less" scoped>
@import url('../../index/style/shop/util');
</style>
<style lang="less">
::-webkit-scrollbar {
  display: none;
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}
.el-dialog {
  border-radius: 10px!important;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 !important;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
  display: flex;
  flex-direction: column;
}
.el-dialog .el-dialog__body{
  overflow: auto;
  color: #666666;
  border-radius:10px;
}
.Jepaas-common-detail {
  position: relative;
  width: 100%;
  height: 100%;
  .el-backtop {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    z-index: 10;
    border-radius: 4px;
    width: 52px;
    height: 52px;
    right: 2% !important;
    bottom: 43px !important;
    font-size: 24px;
    color: #ff3041;
    &:hover {
      background: #ff3041;
      > i {
        color: #ffffff;
      }
    }
  }
  .box_top {
    width: 100%;
    height: 80px;
    background: linear-gradient(
      270deg,
      rgba(252, 103, 81, 1) 0%,
      rgba(246, 71, 77, 1) 100%
    );
  }
  .je-detail-content {
    margin: 0 auto;
    padding: 20px 0;
    width: 1250px;
    .explains {
      margin-top: 30px;
      .el-tabs__item {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 20px;
        font-weight: 400;
        color: rgba(63, 63, 63, 1);
        height: initial;
        line-height: normal;
      }
      .el-tabs__header {
        margin-bottom: 30px;
      }
      .el-tabs__active-bar {
        background-color: #f84e4e;
      }
    }
  }
}
</style>
