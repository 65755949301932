/*
 * @Descripttion:
 * @Author: 宋爽爽
 * @Date: 2020-03-18 14:22:23
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-12 13:42:31
 */
import ajax from '@/assets/js/ajax';
import store from '@/store/index';
/** ;;
 * @name: 收集数据
 * @Descripttion: 页面埋点  切换页面  统计数据
 * @param {to} to 当前路由
 * @param {page} page 什么页面
 * @returns {null}
 */
const collectData = (to, page) => {
  const nameArr = {
    home: '首页',
    gatherPage: '江湖令',
    corePage: '核心',
    productPage: '产品优势',
    functionalPage: '产品架构',
    successfulPage: '客户案例',
    cooperationPage: '合作共赢',
    demonstrationPage: '在线演示',
    videoPage: '视频教程',
    buyingPage: '立即购买',
    downloadPage: 'Gitee下载',
    aboutPage: '关乎我们',
    proJepaasPage: 'JEPaaS',
    proJeimPage: 'JRIM',
    proJeappPage: 'JEAPP',
    proScrmPage: 'SCRM',
    proOaPage: 'OA',
    documentPage: '文档',
    shopPage: '商城',
    loginPage: '登录',
    modifyInfoPage: '完善个人信息',
    paySuccessPage: '支付成功',
    payFailurePage: '支付失败',
    vipPage: 'VIP服务',
    netDiskPage: '网盘',
    questionPage: '问题排查与解决',
    zsPage: '商业证书',
    resourcePage: '资源资料',
    alreadyListPage: '我的订单',
    whiteBarPage: '白条消费',
    invoiceApplyPage: '发票申请',
    invoiceFormPage: '发票表单',
    invoiceDetailsPage: '发票详情',
    contractApplyPage: '合同申请',
    contractFormPage: '合同表单',
    HTDetailsPage: '合同详情',
    certificatePage: '商业证书的使用详情',
  };
  const url = document.referrer && document.referrer != '' ? document.referrer : window.location.href;
  let oldname = to.name;
  let newname = '';
  if (oldname) {
    newname = oldname == 'home' ? 'jepaasPage' : to.name;
  } else {
    newname = oldname = page;
  }
  let pageName;
  let pageCode;
  let pageZd;
  if (nameArr[oldname]) {
    pageName = nameArr[oldname];
    pageCode = newname.substring(0, newname.length - 4);
  } else if (page == '_product' && window.__detail) { // 商品单独处理
    pageName = window.__detail.PRODUCT_SPMC;
    pageCode = window.__detail.PRODUCT_SPBH;
    pageZd = window.__detail.PRODUCT_SPSSFL_CODE;
  }
  // 产品快捷入口单独处理
  if (to.query.data && to.query.data == 'CP') {
    pageName = '产品';
  }
  if (pageCode && pageZd) {
    ajax({
      url: '/jepaas/product/productVisitorLog',
      data: {
        url: window.location.href,
        productName: pageName,
        productType: pageZd,
      },
    });
  }
  if (pageCode) {
    ajax({
      url: '/je/product/paas/adminCopyright/websiteVisitLog',
      data: {
        url,
        pageName,
        pageCode,
      },
    });
  }

  oldname == 'home' ? (document.body.style.overflowY = 'hidden') : (document.body.style.overflowY = 'auto');
  to.meta.id ? store.commit('pageChange', to.meta.id) : '';
  window.scrollTo(0, 0);
};

export default collectData;
