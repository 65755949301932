/*
 * @Descripttion:
 * @Author: 宋爽爽
 * @Date: 2019-09-20 19:41:02
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-04-02 16:00:05
 */

const getters = {
};
export default getters;
