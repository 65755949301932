<template>
  <div class="float_btn_wechart">
    <p>JECloud创始人</p>
    <img :src="enterpriseWechat" alt="" />
  </div>
</template>

<script>
import enterpriseWechat from '../assets/imgs/enterprise.png'

export default {
  name: 'FloatBtnEnterprise',
  data() {
    return {
      enterpriseWechat
    }
  }
}
</script>

<style lang="less" scoped>
.float_btn_wechart {
  padding: 20px;
  > p {
    margin: 0;
    color: #333333;
    font-size: 18px;
    text-align: center;
  }
  > img {
    width: 140px;
    height: 140px;
    margin-top: 20px;
  }
}
</style>
