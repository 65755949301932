/*
 * @Descripttion:
 * @Author: 宋爽爽
 * @Date: 2020-03-13 13:23:18
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-03-24 17:40:39
 */
const root = {
  pageActive: 1,
  headerTop: 0,
  winAct: false,
  moreAct: false,
  addAct: false,
  what: '',
  loginStatus: false,
  userInfo: '',
  productType: 'SYZS', // 产品类型 默认商业证书
  orderInfo: {},
};
export default root;
