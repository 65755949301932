/**
 * 总路由
 */
import Vue from 'vue'
import Router from 'vue-router'
/**
 * 重写路由的push方法
 */
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}
const homePage = () => import('@/pages/index/views/homePage.vue') // 首页
const gatherPage = () => import('@/pages/index/views/gatherPage.vue') // 江湖召集令
const corePage = () => import('@/pages/index/views/corePage.vue') // 核心功能
// const productPage = () => import('@/pages/index/views/productPage.vue'); //产品优势
const functionalPage = () => import('@/pages/index/views/functionalPage.vue') // 平台架构
const successfulPage = () => import('@/pages/index/views/successfulPage.vue') // 客户案例
const successfulDetailPage = () =>
  import('@/pages/index/views/successfulDetailPage.vue') // 客户案例详情
const cooperationPage = () => import('@/pages/index/views/cooperationPage.vue') // 合作共赢
const demonstrationPage = () =>
  import('@/pages/index/views/demonstrationPage.vue') // 在线演示
const videoPlater = () => import('@/pages/index/views/vidoePlayer/index.vue') // 在线演示
const videoPage = () => import('@/pages/index/views/vidoePlayer/videoPage.vue') // 在线演示
const schoolPage = () => import('@/pages/index/views/school/school.vue') // 在线演示
const videoZQZJ = () => import('@/pages/detail/components/videoPlayer.vue') // 商城组件的视频播放显示问题
const playerPage = () =>
  import('@/pages/index/views/vidoePlayer/playerPage.vue') // 在线演示
const buyingPage = () => import('@/pages/index/views/buyingPage.vue') // 立即购买
const downloadPage = () => import('@/pages/index/views/downloadPage.vue') // 免费下载
const aboutPage = () => import('@/pages/index/views/aboutPage.vue') // 关于我们
const iphoneFrom = () => import('@/pages/index/views/iphoneFrom.vue') // 关于我们
const proJepaasPage = () => import('@/pages/index/views/product/jepaas.vue') // 产品-JEPaaS
const proJeimPage = () => import('@/pages/index/views/product/jeim.vue') // 产品-JEIM
const proJeappPage = () => import('@/pages/index/views/product/jeapp.vue') // 产品-JEAPP
const proScrmPage = () => import('@/pages/index/views/product/scrm.vue') // 产品-SCRM
const proOaPage = () => import('@/pages/index/views/product/oa.vue') // 产品-SCRM
const documentPage = () => import('@/pages/index/views/documentPage') // 产品介绍
// 订单相关的页面
const orderIndex = () => import('@/pages/index/views/my/index/index.vue') // 公共父组件跳转
const alreadyListPage = () =>
  import('@/pages/index/views/my/alreadyList/index.vue') // 已购订单
const myTaskPage = () => import('@/pages/index/views/my/myTask/index.vue') // 我的工单
const myAppPage = () => import('@/pages/index/views/my/myApp/index.vue') // 我的APP
const whiteBarPage = () => import('@/pages/index/views/my/whiteBar/index.vue') // 白条消费
const certificatePage = () =>
  import('@/pages/index/views/my/alreadyList/certificateDetails.vue') // 使用详情
const invoicePage = () => import('@/pages/index/views/my/invoice/index.vue') // 发票申请
const indexInvoicePage = () =>
  import('@/pages/index/views/my/invoice/indexInvoice.vue') // 发票申请默认首页
const invoiceApplyPage = () =>
  import('@/pages/index/views/my/invoice/invoiceApply.vue') // 点击按钮申请发票
const invoiceFormPage = () =>
  import('@/pages/index/views/my/invoice/invoiceForm.vue') // 发票申请的表单
const invoiceDetailsPage = () =>
  import('@/pages/index/views/my/invoice/indexDetails.vue') // 发票详情
const contractPage = () => import('@/pages/index/views/my/contract/index.vue') // 合同申请
const indexContractPage = () =>
  import('@/pages/index/views/my/contract/indexContract.vue') // 合同申请默认首页
const contractApplyPage = () =>
  import('@/pages/index/views/my/contract/contractApply.vue') // 点击按钮合同发票
const contractFormPage = () =>
  import('@/pages/index/views/my/contract/contractForm.vue') // 合同申请的表单
const HTDetailsPage = () =>
  import('@/pages/index/views/my/contract/indexDetails.vue') // 合同详情
// 下载App的页面提示
const downloadAppPage = () =>
  import('@/pages/index/views/downloadApp/index.vue') // 下载APP的微信提示信息

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: homePage,
      meta: {
        id: 1,
        content: {
          title: 'JEPaaS_低代码平台_低代码开发_企业SCRM-凯特伟业',
          keywords: 'JEPaaS,低代码平台,低代码开发,企业SCRM,低代码开发平台排名,apaas,制造行业代码,业务流程管理,saas开发框架',
          description: '北京凯特伟业科技有限公司，是一家专门从事企业管理咨询和企业信息化落地服务的信息技术企业。目前，公司主要产品包括JEPaaS开发平台、蒜瓣SCRM等，低代码开发平台排名，apaas，制造行业代码，业务流程管理，saas开发框架，activiti工作流，单点登录，微服务架构，low code，可视化流程设计，移动应用，免费低代码平台，水业工程管理，安全管理系统，CRM管理系统，移动办公平台，并提供定制开发和SaaS服务。',
          author: '凯特伟业',
          copyright: '北京凯特伟业科技有限公司',
        }
      }
    },
    {
      path: '/gather',
      name: 'gatherPage',
      component: gatherPage,
      meta: {
        id: 2
      }
    },
    {
      path: '/core',
      name: 'corePage',
      component: corePage,
      meta: {
        id: 3
      }
    },
    {
      path: '/functional',
      name: 'functionalPage',
      component: functionalPage,
      meta: {
        id: 4 // id=4产品亮点
      }
    },
    {
      path: '/successful',
      name: 'successfulPage',
      component: successfulPage,
      meta: {
        id: 6,
        content: {
          title: 'JEPaaS_客户案例-凯特伟业',
          keywords: 'JEPaaS,低代码快速开发,低代码快速开发平台,企业SCRM,低代码开发平台排名,apaas,制造行业代码,业务流程管理,saas开发框架',
          description: '北京凯特伟业科技有限公司，是一家专门从事企业管理咨询和企业信息化落地服务的信息技术企业。目前，公司主要产品包括JEPaaS开发平台、蒜瓣SCRM等，低代码开发平台排名，apaas，制造行业代码，业务流程管理，saas开发框架，activiti工作流，单点登录，微服务架构，low code，可视化流程设计，移动应用，免费低代码平台，水业工程管理，安全管理系统，CRM管理系统，移动办公平台，并提供定制开发和SaaS服务。',
          author: '凯特伟业',
          copyright: '北京凯特伟业科技有限公司',
        }
      }
    },
    {
      path: '/successfulDetail',
      name: 'successfulDetailPage',
      component: successfulDetailPage
    },
    {
      path: '/cooperation',
      name: 'cooperationPage',
      component: cooperationPage,
      meta: {
        id: 7
      }
    },
    {
      path: '/demonstration',
      name: 'demonstrationPage',
      component: demonstrationPage,
      meta: {
        id: 8,
        content: {
          title: 'JEPaaS_低代码快速开发演示-凯特伟业',
          keywords: 'JEPaaS,低代码快速开发,低代码快速开发平台,企业SCRM,低代码开发平台排名,apaas,制造行业代码,业务流程管理,saas开发框架',
          description: '北京凯特伟业科技有限公司，是一家专门从事企业管理咨询和企业信息化落地服务的信息技术企业。目前，公司主要产品包括JEPaaS开发平台、蒜瓣SCRM等，低代码开发平台排名，apaas，制造行业代码，业务流程管理，saas开发框架，activiti工作流，单点登录，微服务架构，low code，可视化流程设计，移动应用，免费低代码平台，水业工程管理，安全管理系统，CRM管理系统，移动办公平台，并提供定制开发和SaaS服务。',
          author: '凯特伟业',
          copyright: '北京凯特伟业科技有限公司',
        }
      }
    },
    {
      path: '/videoPlater',
      name: 'videoPlater',
      component: videoPlater,
      meta: {
        id: 8
      },
      children: [
        // {
        //   path: '/school',
        //   name: 'schoolPage',
        //   component: schoolPage,
        //   meta: {
        //     id: 8
        //   }
        // },
        {
          path: '/videoCourse',
          name: 'videoPage',
          component: videoPage,
          meta: {
            id: 8,
            content: {
              title: 'JEPaaS_jeapp_JEPaaS教程-凯特伟业',
              keywords: 'JEPaaS,jeapp,全流程规划引擎,物联网套件,即时通讯IM套件,工艺管控引擎,excel导入导出工具,数据权限套件,图报表引擎',
              description: '北京凯特伟业科技有限公司，是一家专门从事企业管理咨询和企业信息化落地服务的信息技术企业。目前，公司主要产品包括JEPaaS开发平台、蒜瓣SCRM等，低代码开发平台排名，apaas，制造行业代码，业务流程管理，saas开发框架，activiti工作流，单点登录，微服务架构，low code，可视化流程设计，移动应用，免费低代码平台，水业工程管理，安全管理系统，CRM管理系统，移动办公平台，并提供定制开发和SaaS服务。',
              author: '凯特伟业',
              copyright: '北京凯特伟业科技有限公司',
            }
          }
        },
        {
          path: '/player',
          name: 'playerPage',
          component: playerPage,
          meta: {
            id: 8
          }
        }
      ]
    },
    {
      path: '/school',
      name: 'schoolPage',
      component: schoolPage,
      meta: {
        id: 18,
        content: {
          title: 'JEPaaS_jeapp_JEPaaS学习中心-凯特伟业',
          keywords: 'JEPaaS,jeapp,全流程规划引擎,物联网套件,即时通讯IM套件,工艺管控引擎,excel导入导出工具,数据权限套件,图报表引擎',
          description: '北京凯特伟业科技有限公司，是一家专门从事企业管理咨询和企业信息化落地服务的信息技术企业。目前，公司主要产品包括JEPaaS开发平台、蒜瓣SCRM等，低代码开发平台排名，apaas，制造行业代码，业务流程管理，saas开发框架，activiti工作流，单点登录，微服务架构，low code，可视化流程设计，移动应用，免费低代码平台，水业工程管理，安全管理系统，CRM管理系统，移动办公平台，并提供定制开发和SaaS服务。',
          author: '凯特伟业',
          copyright: '北京凯特伟业科技有限公司',
        }
      }
    },
    {
      path: '/buying',
      name: 'buyingPage',
      component: buyingPage,
      meta: {
        id: 9
      }
    },
    {
      path: '/downloadApp',
      name: 'downloadAppPage',
      component: downloadAppPage
    },
    {
      path: '/download',
      name: 'downloadPage',
      component: downloadPage,
      meta: {
        id: 10,
        content: {
          title: 'JEPaaS_jeapp_JEPaaS资源下载-凯特伟业',
          keywords: 'JEPaaS,jeapp,全流程规划引擎,物联网套件,即时通讯IM套件,工艺管控引擎,excel导入导出工具,数据权限套件,图报表引擎',
          description: '北京凯特伟业科技有限公司，是一家专门从事企业管理咨询和企业信息化落地服务的信息技术企业。目前，公司主要产品包括JEPaaS开发平台、蒜瓣SCRM等，低代码开发平台排名，apaas，制造行业代码，业务流程管理，saas开发框架，activiti工作流，单点登录，微服务架构，low code，可视化流程设计，移动应用，免费低代码平台，水业工程管理，安全管理系统，CRM管理系统，移动办公平台，并提供定制开发和SaaS服务。',
          author: '凯特伟业',
          copyright: '北京凯特伟业科技有限公司',
        }
      }
    },
    {
      path: '/about',
      name: 'aboutPage',
      component: aboutPage,
      meta: {
        id: 11,
        content: {
          title: 'JEPaaS_jeapp_JEPaaS企业介绍-凯特伟业',
          keywords: 'JEPaaS,jeapp,全流程规划引擎,物联网套件,即时通讯IM套件,工艺管控引擎,excel导入导出工具,数据权限套件,图报表引擎',
          description: '北京凯特伟业科技有限公司，是一家专门从事企业管理咨询和企业信息化落地服务的信息技术企业。目前，公司主要产品包括JEPaaS开发平台、蒜瓣SCRM等，低代码开发平台排名，apaas，制造行业代码，业务流程管理，saas开发框架，activiti工作流，单点登录，微服务架构，low code，可视化流程设计，移动应用，免费低代码平台，水业工程管理，安全管理系统，CRM管理系统，移动办公平台，并提供定制开发和SaaS服务。',
          author: '凯特伟业',
          copyright: '北京凯特伟业科技有限公司',
        }
      }
    },
    {
      path: '/iphoneFrom',
      name: 'iphoneFrom',
      component: iphoneFrom
    },
    {
      path: '/product_jepaas',
      name: 'proJepaasPage',
      component: proJepaasPage,
      meta: {
        id: 12
      }
    },
    {
      path: '/product_jeim',
      name: 'proJeimPage',
      component: proJeimPage
    },
    {
      path: '/product_jeapp',
      name: 'proJeappPage',
      component: proJeappPage
    },
    {
      path: '/product_scrm',
      name: 'proScrmPage',
      component: proScrmPage
    },
    {
      path: '/product_oa',
      name: 'proOaPage',
      component: proOaPage
    },
    {
      path: '/document',
      name: 'documentPage',
      component: documentPage
    },
    /* { // 登录 启用多页面
      path: '/modifyInfo',
      component: () => import('@/pages/login/views/modifyInfo'),
      name: 'modifyInfo',
      // meta: { title: '完善个人信息', noCache: true }
    }, */
    {
      path: '/shopVideo',
      name: 'videoPlayerPage',
      component: videoZQZJ
    },
    {
      path: '/modifyInfo',
      component: () => import('@/pages/index/views/login/modifyInfo'),
      meta: {
        auth: true // 页面需要登录才可跳转
      },
      name: 'modifyInfoPage'
      // meta: { title: '完善个人信息', noCache: true }
    },
    //  订单的公共父组件
    {
      path: '/order',
      component: orderIndex,
      name: 'orderIndex',
      children: [
        {
          path: 'invoice',
          name: 'invoicePage',
          component: invoicePage,
          children: [
            {
              path: 'indexInvoice',
              name: 'indexInvoicePage',
              component: indexInvoicePage
            },
            {
              path: 'invoiceApply',
              name: 'invoiceApplyPage',
              component: invoiceApplyPage
            },
            {
              path: 'invoiceForm',
              name: 'invoiceFormPage',
              component: invoiceFormPage
            }
          ]
        },
        {
          path: 'invoiceDetails',
          name: 'invoiceDetailsPage',
          component: invoiceDetailsPage
        },
        {
          path: 'contract',
          name: 'contractPage',
          component: contractPage,
          children: [
            {
              path: 'indexContract',
              name: 'indexContractPage',
              component: indexContractPage
            },
            {
              path: 'contractApply',
              name: 'contractApplyPage',
              component: contractApplyPage
            },
            {
              path: 'contractForm',
              name: 'contractFormPage',
              component: contractFormPage
            }
          ]
        },
        {
          path: 'contractDetails',
          name: 'contractDetailsPage',
          component: HTDetailsPage
        }
      ]
    },
    //  已购订单的跳转
    {
      path: '/alreadyList',
      component: alreadyListPage,
      meta: {
        auth: true // 页面需要登录才可跳转
      },
      name: 'alreadyListPage'
    },
    //  我的工单的跳转
    {
      path: '/myTask',
      component: myTaskPage,
      meta: {
        auth: true // 页面需要登录才可跳转
      },
      name: 'myTaskPage'
    },
    //  我的App升级
    {
      path: '/myApp',
      component: myAppPage,
      meta: {
        auth: true // 页面需要登录才可跳转
      },
      name: 'myAppPage'
    },
    //  使用详情商业证书
    {
      path: '/certificateShow',
      component: certificatePage,
      name: 'certificatePage'
    },
    //  白条消费
    {
      path: '/whiteBarPay',
      component: whiteBarPage,
      name: 'whiteBarPage'
    },
    {
      path: '/promotion',
      component: () => import('@/pages/index/views/shop/618promotion'),
      name: 'promotion'
    }, 
    {
      path: '/consult',
      component: () => import('@/pages/index/views/consult/index'),
      name: 'consult'
    },
    {
      path: '/shop',
      component: () => import('@/pages/index/views/shop/shop'),
      name: 'shopPage',
      /* children: [
        {
          path: '/paySuccess',
          name: 'paySuccess',
          component: () => import('@/pages/index/views/shop/paySuccess'),
        },
      ], */
      // meta: { title: '商城', noCache: true },
      meta: {
        content: {
          title: 'JEPaaS_jeapp_全流程规划引擎_物联网套件-凯特伟业',
          keywords: 'JEPaaS,jeapp,全流程规划引擎,物联网套件,即时通讯IM套件,工艺管控引擎,excel导入导出工具,数据权限套件,图报表引擎',
          description: '北京凯特伟业科技有限公司，是一家专门从事企业管理咨询和企业信息化落地服务的信息技术企业。目前，公司主要产品包括JEPaaS开发平台、蒜瓣SCRM等，低代码开发平台排名，apaas，制造行业代码，业务流程管理，saas开发框架，activiti工作流，单点登录，微服务架构，low code，可视化流程设计，移动应用，免费低代码平台，水业工程管理，安全管理系统，CRM管理系统，移动办公平台，并提供定制开发和SaaS服务。',
          author: '凯特伟业',
          copyright: '北京凯特伟业科技有限公司',
        }
      }
    },
    {
      path: '/login',
      component: () => import('@/pages/index/views/login/login'),
      name: 'loginPage'
      // meta: { title: '商城', noCache: true }
    },
    {
      path: '/paySuccess',
      name: 'paySuccessPage',
      component: () => import('@/pages/index/views/shop/paySuccess'),
      meta: { parent: 'shop' }
    },
    {
      path: '/payFailure',
      name: 'payFailurePage',
      component: () => import('@/pages/index/views/shop/payFailure'),
      meta: { parent: 'shop' }
    },
    {
      path: '/platform',
      name: 'platformPage',
      component: () => import('@/pages/index/views/platform/index.vue'),
      meta: {
        content: {
          title: 'JEPaaS_低代码快速开发_低代码快速开发平台-凯特伟业',
          keywords: 'JEPaaS,低代码快速开发,低代码快速开发平台,企业SCRM,低代码开发平台排名,apaas,制造行业代码,业务流程管理,saas开发框架',
          description: '北京凯特伟业科技有限公司，是一家专门从事企业管理咨询和企业信息化落地服务的信息技术企业。目前，公司主要产品包括JEPaaS开发平台、蒜瓣SCRM等，低代码开发平台排名，apaas，制造行业代码，业务流程管理，saas开发框架，activiti工作流，单点登录，微服务架构，low code，可视化流程设计，移动应用，免费低代码平台，水业工程管理，安全管理系统，CRM管理系统，移动办公平台，并提供定制开发和SaaS服务。',
          author: '凯特伟业',
          copyright: '北京凯特伟业科技有限公司',
        }
      }
    },
    {
      path: '/referCenter',
      name: 'referCenterPage',
      component: () => import('@/pages/index/views/referCenter/index.vue')
    }
    // 新加一个临时首页
    /* {
      path: '/home-dev',
      name: 'homedevPage',
      component: () => import('@/pages/index/views/homePage_new.vue'),
    }, */
  ]
})
