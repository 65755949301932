<!--
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-04-03 09:47:23
 * @LastEditTime: 2020-05-15 15:51:53
 -->
<template>
  <div class="rgfw-pay">
    <div class="color-3F3F3F font-size-24 font-weight-600">
      {{ mock.title.name }}
    </div>
    <div
      class="img margin-top-20"
    >
      <img
        style="width: 100%"
        v-lazy="mock.title.banner ? `/jepaas/document/preview?fileKey=${mock.title.banner}` : `../assets/images/wp/wp.png`"
        alt
      >
    </div>
    <div class="shop-common-title">
      <!-- 喇叭提示内容 -->
      <div
        v-if="mock.title.hint"
        class="padding-left-30 trumpet-background trumpet-color height-50 line-height-50"
      >
        <i class="jeicon jeicon-horn-o vertical-align-middle font-size-20" />
        <span class="vertical-align-middle font-size-18">{{ mock.title.hint }}</span>
      </div>
      <!-- 背景图 -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mock: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="less" scoped>
@import url('../../index/style/shop/util');
.img {
  margin-bottom: 20px;
  height: 300px;
  font-size: 0;
}
.shop-common-title {
  .title {
    font-size: 30px;
    font-weight: 600;
  }
  .attent {
    font-size: 20px;
    color: rgba(239, 77, 77, 1);
    margin: 20px 0 30px 0;
  }
}
</style>

<style lang="less">
.rgfw-pay {
  .el-image__inner {
    height: 300px;
    overflow: hidden;
  }
  .el-image__error {
    min-height: 300px;
  }
}
</style>
