<!--
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-04-03 10:37:32
 * @LastEditTime: 2020-09-01 13:38:09
 -->
<template>
  <!-- 下方切换的动态公共组件 -->
  <div class="jepass-shop-zyzl-tabs">
    <!-- 产品说明tabs部分 -->
    <el-tabs
      v-model="activeName"
      class="explains"
    >
      <el-tab-pane
        label="视频详情"
        name="CPXQ"
      >
        <div>
          <div
            class="font-size-20"
            v-for="(info, index) in mock.info"
            :key="index"
          >
            <div
              class="margin-top-20"
              v-if="info.type == 'text'"
            >
              <div
                class="font-weight-600 padding-left-20 border-left-6-solid-F9524F line-height-50 height-50"
                v-if="info.content.title"
              >
                {{ info.content.title }}
              </div>
              <div
                class="padding-left-40 margin-top-20"
                v-if="info.content.main"
              >
                {{ info.content.main }}
              </div>
              <!-- 关联更多显示 -->
              <div
                v-if="info.content.titleUrl"
                class="margin-top-20 font-size-18"
              >
                关联更多: <span
                  class="font-size-16 cursor-pointer-hover"
                  style="color: rgb(64,158,255)"
                  @click="goUrl(info.content.url)"
                >{{ info.content.titleUrl }}</span>
              </div>
            </div>
            <!-- 图片显示 -->
            <div
              class="margin-top-20"
              v-if="info.type == 'img'"
            >
              <div
                class="font-weight-600 padding-left-20 border-left-6-solid-F9524F line-height-50 height-50"
                v-if="info.content.title"
              >
                {{ info.content.title }}
              </div>
              <!-- 如果存在图片的话进行循环显示 -->
              <div v-if="info.content.main">
                <div
                  v-for="(img, _img) in info.content.main"
                  :key="_img"
                  class="margin-top-20"
                >
                  <img
                    style="width: 100%"
                    class="imgs"
                    v-lazy="img.path ? `/jepaas/document/preview?fileKey=${img.path}` : ``"
                    alt
                  >
                </div>
              </div>
              <!-- 关联更多显示 -->
              <div
                v-if="info.content.titleUrl"
                class="margin-top-20 font-size-18"
              >
                关联更多: <span
                  class="font-size-16 cursor-pointer-hover"
                  style="color: rgb(64,158,255)"
                  @click="goUrl(info.content.url)"
                >{{ info.content.titleUrl }}</span>
              </div>
            </div>
            <!-- 图文显示 -->
            <div
              class="margin-top-20"
              v-if="info.type == 'html'"
            >
              <div
                class="font-weight-600 padding-left-20 border-left-6-solid-F9524F line-height-50 height-50"
                v-if="info.content.title"
              >
                {{ info.content.title }}
              </div>
              <div
                class="padding-left-40 margin-top-20"
                v-if="info.content.main"
              >
                <div
                  class="html_img"
                  v-html="info.content.main"
                />
              </div>
              <!-- 关联更多显示 -->
              <div
                v-if="info.content.titleUrl"
                class="margin-top-20 font-size-18"
              >
                关联更多: <span
                  class="font-size-16 cursor-pointer-hover"
                  style="color: rgb(64,158,255)"
                  @click="goUrl(info.content.url)"
                >{{ info.content.titleUrl }}</span>
              </div>
            </div>
            <!-- 视频显示 -->
            <div
              class="margin-top-20"
              v-if="info.type == 'video'"
            >
              <div
                class="font-weight-600 padding-left-20 border-left-6-solid-F9524F line-height-50 height-50"
                v-if="info.content.title"
              >
                {{ info.content.title }}
              </div>
              <div
                class="padding-left-40 margin-top-20"
                v-if="info.content.main"
              >
                <div
                  :id="`${info.content.vidId}`"
                  ref="player"
                  class="prism-player video_con"
                />
              </div>
              <!-- 关联更多显示 -->
              <div
                v-if="info.content.titleUrl"
                class="margin-top-20 font-size-18"
              >
                关联更多: <span
                  class="font-size-16 cursor-pointer-hover"
                  style="color: rgb(64,158,255)"
                  @click="goUrl(info.content.url)"
                >{{ info.content.titleUrl }}</span>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="视频目录"
        name="CPGG"
      >
        <div class="min-height-500">
          <div
            v-for="(item, index) in list"
            @click="playVideo(item)"
            :key="index"
            class="height-60 padding-left-10 cursor-pointer-hover-F9514F"
            :class="index % 2 != 0 ? 'background-F5F5F5': ''"
          >
            <span
              v-if="!vip(item) || money == 0"
              class="vip-label font-size-14 background-376BD0 color-FFFFFF line-height-60 vertical-align-middle border-radius-5 padding-LR-4"
            >试看</span>
            <span
              v-else
              class="vip-label font-size-14 background-FFA200 color-FFFFFF line-height-60 vertical-align-middle border-radius-5 padding-LR-4"
            >付费</span>
            <span
              class="vertical-align-middle line-height-60 font-size-18 margin-left-10"
            >{{ index+1 }}.{{ item.ZYMXB_ZYMC }}</span>
            <span
              class="color-A9ABA9 display-inlineBlock min-width-120 cursor-pointer-hover-F9514F font-size-14 padding-top-20 vertical-align-middle float-right"
            >
              <i
                class="jeicon jeicon-play-circle-o font-size-16 vertical-align-middle margin-right-5"
              />
              立即播放
              <!-- {{ enablePlay(item)? '升级VIP' : '立即播放' }} -->
            </span>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { getResourceData } from '@/pages/detail/actions/actions';

export default {
  name: 'CommonTabs',
  props: {
    mock: {
      type: Object,
      default: -1,
    },
  },
  data() {
    return {
      productCode: this.mock.shopCode,
      money: this.mock.payVideo.money,
      activeName: 'CPXQ',
      zyTabsLoading: false,
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    goUrl(url) {
      window.open(url);
    },
    vip(item) {
      return item.ZYMXB_YESORNO_CODE != 1;
    },
    /**
     * 是否允许播放
     */
    enablePlay(item) {
      // 用户是否vip
      const vip = !!this.$store.state.userInfo.vip;
      // 商品是否是vip免费
      const vipFeee = true;
      // 商品是否购买
      const order = true;
      // 是vip，并且商品是vip免费
      if (vipFeee && vip) {
        return true;
      // 如果够买了，就可以看
      } if (order) {
        return true;
      }
      return !vip(item);
    },
    // 视频标题开头是否是数字开头
    rerealNumber(number) {
      const reRealNumber1 = /^[1-9]+[\.]+[\s\S]*$/; // 非零开头
      const reRealNumber2 = /^[0-9]+[\s\S]*$/; // 零开头
      // 零开头
      if (reRealNumber2.test(number)) {
        return number.substring(2);
      }
      // 非零开头的数字
      if (reRealNumber1.test(number)) {
        const numArr = number.split('.');
        const numString = `${numArr[1]}.${numArr[2]}`;
        return numString;
      }
      return number;
    },
    getList() {
      getResourceData({ type: 'resource', productCode: this.productCode }).then((res) => {
        if (res.success) {
          this.list = res.rows || res.obj || [];
          this.list.forEach((video, _video) => {
            this.list[_video].ZYMXB_ZYMC = this.rerealNumber(video.ZYMXB_ZYMC);
          });
        }
      });
    },
    // 点击播放视频的按钮
    playVideo(data) {
      this.$router.push({
        path: '/shopVideo',
        query: {
          code: this.productCode.toLocaleLowerCase(),
          video: data.JEPAAS_ZYMXB_ID
        }
      });
       
      // window.open(`/shop/video/${this.productCode.toLocaleLowerCase()}/${data.JEPAAS_ZYMXB_ID}`);
      // if (this.enablePlay(data)) {
      // } else {
      //   this.$emit('upgradeVip', data);
      // }
    },
  },
};
</script>

<style lang="less" scoped>
@import url('../../index/style/shop/util');
.vip-label {
    width: 1.8rem;
    display: inline-block;
    text-align: center;
    height: 40%;
    line-height: 1.8;
}
</style>
<style lang="less">
.jepass-shop-zyzl-tabs {
  p {
   margin: 0 !important;
  }
  ol {
    margin: 0!important
  }
  .el-image__error{
    min-height: 300px;
  }
  .html_img img{
    width: 100%;
    max-height: 500px;
  }
  .imgs {
    max-height: 500px;
  }
}
</style>
