/*
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-05-11 13:30:50
 * @LastEditTime: 2020-05-11 13:31:19
 */
import LoadingComponent from './loading.vue';

const Loading = {
  install(Vue) {
    Vue.component('Loading', LoadingComponent);
  },
};
// 导出组件
export default Loading;
