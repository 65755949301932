<!--
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-05-11 11:18:54
 * @LastEditTime: 2020-05-19 10:47:02
 -->
<template>
  <div
    v-if="loadingData.isShow"
    class="jepaas-shop-loading"
  >
    <!-- loading的背景样式组件 -->
    <div
      class="loadingDiv"
      :style="loadingData.bgColor && `background: ${loadingData.bgColor}` || 'background: rgba(255, 255, 255, .9)'"
    >
      <div class="preloader" />
      <!-- 加载的文字和icon -->
      <div class="loadingText">
        <i
          :class="loadingData.icon || 'jeicon jeicon-elephant'"
        />
        <p v-if="loadingData.text">
          {{ loadingData.text }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loadingData:
      {
        type: Object,
        default() {
          return {
            bgColor: '',
            isShow: false, // 是否显示loading
            text: '', // 加载的文案
            icon: 'jeicon jeicon-elephant', // 加载的icon，默认就是jepaas商城的icon
          };
        },
      },
  },
  data() {
    return {

    };
  },
  methods: {

  },
};
</script>

<style lang="less" scoped>
 .jepaas-shop-loading {
   .loadingDiv {
    position: absolute;
    z-index: 2000;
    opacity: 0.9;
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity .3s;
    .loadingText {
      top: 50%;
      margin-top: -50px;
      width: 100%;
      height: 100px;
      text-align: center;
      position: absolute;
      i {
        color: #F84E4E;
        font-size: 40px;
      }
    }
   }
 }
 .preloader {
    position: absolute;
    width: 60px;
    height: 60px;
    top: 50%;
    margin-top: -60px;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    overflow: hidden;
    animation: animatePreloader 1s infinite linear;
    transform-origin: 50% 100%;
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 50%;
      top: 50%;
      transform: translateX(-50%);
      border: 3px solid #F84E4E;
      border-radius: 50%;
      box-sizing: border-box;
    }
}
 @keyframes animatePreloader {
    0% {
        transform: translateX(-50%) translateY(-50%) rotateZ(0deg);
    }
    100% {
        transform: translateX(-50%) translateY(-50%) rotateZ(360deg);
    }
}
</style>
