/*
 * @Descripttion: 简要描述 ——
 * @Author: Shuangshuang Song
 * @Date: 2020-03-26 13:54:59
 * @LastEditTime: 2020-04-29 18:39:35
 */
const skipUrl = ['/downloadApp', '/app.html']; // 不需要rem计算的页面
if (!skipUrl.includes(window.location.pathname)) {
  console.log();
  // 基准大小
  const baseSize = 24;
  // 设置 rem 函数
  // eslint-disable-next-line no-inner-declarations
  function setRem() {
  // 当前页面宽度相对于 1920 宽的缩放比例，可根据自己需要修改。
    const scale = document.documentElement.clientWidth / 1920;
    // 设置页面根节点字体大小
    // document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2))<=20?20+ 'px':(baseSize * Math.min(scale, 2)) + 'px';
    document.documentElement.style.fontSize = `${baseSize * Math.min(scale, 2)}px`;
  }
  // 初始化
  setRem();
  // 改变窗口大小时重新设置 rem
  window.onresize = () => {
    setRem();
  };
}
