import { render, staticRenderFns } from "./dialogtitle.vue?vue&type=template&id=262c36e2&scoped=true&"
import script from "./dialogtitle.vue?vue&type=script&lang=js&"
export * from "./dialogtitle.vue?vue&type=script&lang=js&"
import style0 from "./dialogtitle.vue?vue&type=style&index=0&id=262c36e2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "262c36e2",
  null
  
)

export default component.exports