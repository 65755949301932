/*
 * @Descripttion:
 * @Author: 张明尧
 * @Date: 2020-04-02 16:32:57
 * @LastEditTime: 2020-05-25 11:05:51
 */
import Vue from 'vue';
import { IMG_SUANBANYUN } from '../actions/api';

const mock = {
  shopCode: '',
  payBtns: '',
  title: {
    name: '',
    hint: '',
    banner: '', // 头部内容的背景图
  },
  // 页面中间的支付相关的数据
  payVideo: {
    free: '', // 是否免费
    url: '', // 资源资料的视频地址
    name: '', // 视频的名称
    labels: [], // 标签测试数据
    money: 0, // 视频购买的价格
    isVip: false, // VIP是否免费观看
    code: 'ZYZL', // 当前组件的编码  传递到payButtons的 payType
  },
  // 公共组件的详情的mock数据
  // 产品详情
  info: [
  ],
  // 产品详情中的视频连接
  infoVideos: [],
  // 产品规格
  attr: [
  ],
};
/**
     * 播放视频
     */
function doPlayer(vid, playauth, content) {
  if (content.player) {
    if (content.player.replayByVidAndPlayAuth) {
      content.player.replayByVidAndPlayAuth(vid, playauth);
    } else {
      content.player.dispose();
      delete content.player;
      content.$refs.playauth.el.innerHTML = '';
      content.doPlayer(vid, playauth);
    }
  } else {
    content.player = new window.Aliplayer({
      id: content.vidId,
      qualitySort: 'asc',
      format: 'm3u8',
      mediaType: 'video',
      encryptType: 1,
      vid,
      playauth,
      // width: '100%',
      height: '500px',
      autoplay: false,
      isLive: false,
      cover: content.CoverUrl,
      rePlay: true,
      playsinline: true,
      preload: true,
      controlBarVisibility: 'hover',
      useH5Prism: true,
    }, (() => {
      console.log('The player is created');
    }));
  }
}
// 获取视频的path
function playVideo(content) {
  Vue.prototype.$ajax({
    url: '/jepaas/vod/getVideoPlayAuth',
    data: {
      videoId: content.main,
    },
  }).then((res) => {
    const data = res.data;
    if (data.success) {
      content.PlayAuth = data.obj.PlayAuth;
      doPlayer(content.main, content.PlayAuth, content);
    } else {
      Vue.prototype.$message({
        message: `${data.message}`,
        type: 'error',
      });
    }
  });
}
/* 2.用浏览器内部转换器实现html解码 */
function htmlDecode(text) {
  const reg = new RegExp('/je/document/down', 'g');
  const path = IMG_SUANBANYUN;
  // 1.首先动态创建一个容器标签元素，如DIV
  let temp = document.createElement('div');
  // 2.然后将要转换的字符串设置为这个元素的innerHTML(ie，火狐，google都支持)
  temp.innerHTML = text;
  temp.innerHTML = temp.innerHTML.replace(reg, path);
  // 3.最后返回这个元素的innerText(ie支持)或者textContent(火狐，google支持)，即得到经过HTML解码的字符串了。
  const output = temp.innerText || temp.textContent;
  temp = null;
  return output;
}
/**
 * 内容的公共格式过滤
 * @param {Object} data 每条数据的内容
 */
function contentFormat(data) {
  let content;
  switch (data.SM_SPXXSMLX_CODE) {
    case 'WZ':
      content = {
        title: data.SM_BT || '',
        titleUrl: data.SM_GLGDWZ || '', // 关联更多的文字
        url: data.SM_GLGDLJ || '', // 关联更多的地址
        main: data.SM_WZ || '',
      };
      break;
    case 'TW':
      content = {
        title: data.SM_BT || '',
        titleUrl: data.SM_GLGDWZ || '', // 关联更多的文字
        url: data.SM_GLGDLJ || '', // 关联更多的地址
        main: htmlDecode(data.SM_TW) || '',
      };
      break;
    case 'TP':
      content = {
        title: data.SM_BT || '',
        titleUrl: data.SM_GLGDWZ || '', // 关联更多的文字
        url: data.SM_GLGDLJ || '', // 关联更多的地址
        main: JSON.parse(data.SM_TP) || '',
      };
      break;
    case 'SP':
      content = {
        title: data.SM_BT,
        titleUrl: data.SM_GLGDWZ || '', // 关联更多的文字
        url: data.SM_GLGDLJ || '', // 关联更多的地址
        vidId: `vid${data.SM_DBID}`,
        main: data.SM_DBID || '',
      };
      playVideo(content);
      break;
  }
  return content;
}
// 根据 * 切割key
/* function imgSplit(img) {
  let keyData = [];
  // eslint-disable-next-line no-const-assign
  keyData = img.split('*');
  return keyData[1];
} */
// 根据接口数据进行过滤并格式化
function format() {
  const _details = window.__detail;
  mock.shopCode = _details.PRODUCT_SPBH; // 商品编码
  mock.payBtns = _details.PRODUCT_ZFFS_CODE; // 支持的支付方式
  mock.payVideo.name = _details.PRODUCT_JYSM; // 头部的名称
  mock.title.hint = _details.PRODUCT_TSSM; // 温馨提示
  mock.payVideo.free = _details.PRODUCT_SPMF_CODE; // 是否免费观看
  mock.payVideo.labels = _details.PRODUCT_SPBQ && _details.PRODUCT_SPBQ.split(','); // 商品的标签
  mock.payVideo.isVip = _details.PRODUCT_YESORNO_NAME == '是' && true || false; // 是否VIP免费
  mock.payVideo.money = _details.PRODUCT_CKJG || 0; // 商品价格
  mock.image = `/jepaas/document/preview?fileKey=${(_details.PRODUCT_TB || '').split('*')[1]}`;// 商品图片
  // 小喇叭提示内容 ！！！
  const infoType = {
    TW: 'html',
    WZ: 'text',
    TP: 'img',
    SP: 'video',
  }; // 对应接口的数据替换 type
  // banner图 ！！！
  const _info = window.__infolist;
  _info.forEach((prot_info) => {
    const obj = {};
    obj.type = infoType[prot_info.SM_SPXXSMLX_CODE]; // 产品详情的类型  文字or图片
    obj.content = contentFormat(prot_info); // 内容 or 图片的地址
    mock.info.push(obj);
  });
  const _attr = window.__attrlist;
  _attr.forEach((prot_attr) => {
    const obj = {};
    obj.key = prot_attr.SMGG_GGSM; // 产品规格对应的key
    obj.content = prot_attr.SMGG_GGXX; // 产品规格的详细描述
    mock.attr.push(obj);
  });
}
format();
export default mock;
