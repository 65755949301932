/*
 * @Descripttion:
 * @Author: 宋爽爽
 * @Date: 2020-03-13 13:23:18
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-04-24 11:00:46
 */
const mutations = {
  pageChange(state, value) {
    state.pageActive = value;
  },
  topChange(state, value) {
    state.headerTop = value;
  },
  winActChange(state, value) {
    state.winAct = value;
  },
  moreActChange(state, value) {
    state.moreAct = value;
  },
  addActChange(state, value) {
    state.addAct = value;
  },
  whatChange(state, value) {
    state.what = value;
  },
  hasLogin(state, value) {
    state.loginStatus = value;
  },
  getUserinfo(state, value) {
    state.userInfo = value;
  },
  setProductType(state, value) {
    state.productType = value;
  },
  getOrderInfo(state, value) {
    state.orderInfo = value;
  },
};
export default mutations;
