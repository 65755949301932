<template>
  <div class="float_btn">
    <!-- <div class="btn_wrp" @click="handleBtn('qq')">
      <i class="jeicon jeicon-qq1" />
    </div>
    <div class="btn_wrp">
      <i class="jeicon jeicon-wechat" />
      <Dialog
        :type="'wechart'"
      />
    </div>
    <div class="btn_wrp">
      <i class="jeicon jeicon-phone" />
      <Dialog
        :type="'phone'"
      />
    </div>
    <div class="btn_wrp">
      <i class="jeicon jeicon-pencil" />
    </div> -->
    <div v-for="(item, index) in btnlist" :key="index" class="btn_wrp">
      <div
        class="btns"
        @click.stop="handleBtn(item.type)"
        @mouseover="mouseover(item.type)"
        @mouseout="mouseout()"
      >
        <i
          :class="item.icon"
          :style="{
            'font-weight': item.type == 'enterprise' ? 'bold' : 'normal'
          }"
        />
      </div>
      <Dialogwrp
        v-if="item.type != 'qq'"
        :ref="item.type"
        :type="item.type"
        :show="item.show"
        @handleClose="handleClose"
      />
    </div>
  </div>
</template>

<script>
import Dialogwrp from './components/dialogwrp'

export default {
  name: 'FloatBtn',
  components: { Dialogwrp },
  data() {
    return {
      btnlist: [
        {
          icon: 'jeicon jeicon-enterprise-wechat-01',
          type: 'enterprise',
          show: false
        },
        {
          icon: 'jeicon jeicon-public-account',
          type: 'wechat',
          show: false
        },
        // {
        //   icon: 'jeicon jeicon-wechat',
        //   type: 'wechat',
        //   show: false
        // },
        {
          icon: 'jeicon jeicon-phone',
          type: 'phone',
          show: false
        },
        {
          icon: 'jeicon jeicon-pencil',
          type: 'pencil',
          show: false
        }
      ]
    }
  },
  methods: {
    setVisible(visible, type) {
      this.btnlist.forEach(item => {
        if (item.type == type) {
          item.show = visible
        } else {
          item.show = false
        }
      })
    },
    handleBtn(type) {
      this.setVisible(false)
      if (type == 'qq') {
        window.open(
          'http://shang.qq.com/wpa/qunwpa?idkey=3d0bc04e6a1e43055b8e69fd8324c477a45fa1cba57cfcd03dc95ba61427c52c'
        )
      }
      if (['phone', 'pencil'].indexOf(type) > -1) {
        this.setVisible(true, type)
      }
    },
    mouseover(type) {
      if (type == 'wechat' || type == 'enterprise') {
        this.setVisible(true, type)
      }
    },
    mouseout() {
      this.btnlist.forEach(item => {
        if (item.type == 'wechat' || item.type == 'enterprise') {
          item.show = false
        }
      })
      // this.setVisible(false, 'wechat');
    },
    handleClose(type) {
      this.setVisible(false, type)
    }
  }
}
</script>

<style lang="less" scoped>
.float_btn {
  position: fixed;
  // top: 75vh;
  right: 2%;
  width: 52px;
  z-index: 10;
  bottom: 100px;
  z-index: 1000;
  > .btn_wrp {
    width: 52px;
    height: 52px;
    margin-top: 5px;
    background: #ffffff;
    position: relative;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    > .btns {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 300ms;
      border-radius: 4px;
      > i {
        font-size: 24px;
        color: #ff3041;
      }
      &:hover,
      &.active {
        background: #ff3041;
        > i {
          color: #ffffff;
        }
      }
    }
  }
}
</style>
