<template>
  <div>
    <!-- <div class="float_con">
      <div class="float_but1"
           @click=openqq()><img src="../assets/imgs/floatBut/but_1.png"
             alt=""></div>
      <div class="float_but2"
           @click=openWin(1)><img src="../assets/imgs/floatBut/but_2.png"
             alt=""></div>
      <div class="float_but3"
           @click=openWin(2)><img src="../assets/imgs/floatBut/but_3.png"
             alt=""></div>
    </div> -->
    <div>
      <el-dialog
        :width="wit"
        :visible.sync="dialogFormVisible"
      >
        <!-- but打开 -->
        <div
          v-if="openImg==2"
          class="page_box"
        >
          <div class="title_con">
            <span
              :style="{color:(showWin==1?'rgba(51,51,51,1)':'#999999'),borderColor:(showWin==1?'rgba(51,51,51,1)':'transparent')}"
              @click="showOpen(1)"
            >加入社区</span>
            <span
              :style="{color:(showWin==2?'rgba(51,51,51,1)':'#999999'),borderColor:(showWin==2?'rgba(51,51,51,1)':'transparent')}"
              @click="showOpen(2)"
            >留言咨询</span>
            <span
              :style="{color:(showWin==3?'rgba(51,51,51,1)':'#999999'),borderColor:(showWin==3?'rgba(51,51,51,1)':'transparent')}"
              @click="showOpen(3)"
            >电话联系</span>
          </div>
          <!-- 社区 -->
          <div
            v-if="showWin==1"
            class="qq_con"
          >
            <div class="img_con">
              <img
                src="../assets/imgs/floatBut/qq.png"
                alt=""
              >
              <div class="data_con">
                <div>欢迎加入官方QQ群，随时咨询！</div>
                <div>QQ群：462151894</div>
              </div>
            </div>
            <div
              class="buttonShen"
              @click="openqq()"
            >
              加入社区
            </div>
          </div>
          <!-- 表单 -->
          <div
            v-if="showWin==2"
            class="from_con"
          >
            <el-form
              ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              label-width="70px"
              class="demo-ruleForm"
            >
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item
                    label="姓名"
                    prop="name"
                  >
                    <el-input
                      v-model="ruleForm.name"
                      placeholder="请输入您的姓名"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="职务">
                    <el-input
                      v-model="ruleForm.duty"
                      placeholder="请输入您的职务"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row
                class="mt-20"
                :gutter="20"
              >
                <el-col :span="12">
                  <el-form-item
                    label="电话"
                    prop="phone"
                  >
                    <el-input
                      v-model="ruleForm.phone"
                      placeholder="请输入联系电话"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="邮箱">
                    <el-input
                      v-model="ruleForm.email"
                      placeholder="请输入邮箱地址"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row
                class="mt-20 el_li"
                :gutter="20"
              >
                <el-col :span="24">
                  <el-row>
                    <el-col :span="8">
                      <el-form-item
                        label="地址"
                        prop="provinceValue"
                      >
                        <el-select
                          v-model="ruleForm.provinceValue"
                          placeholder="省份"
                          @change="provinceChange"
                        >
                          <el-option
                            v-for="(item,index) in province"
                            :key="index"
                            :label="item.label"
                            style="padding: 0 20px"
                            :value="index"
                          />
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item
                        class="text-center"
                        prop="cityValue"
                      >
                        <el-select
                          v-model="ruleForm.cityValue"
                          :disabled="cityStatus"
                          placeholder="城市"
                          @change="cityChange"
                        >
                          <el-option
                            v-for="(item,index) in city"
                            :key="index"
                            :label="item.label"
                            style="padding: 0 20px"
                            :value="index"
                          />
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item prop="countyValue">
                        <el-select
                          v-model="ruleForm.countyValue"
                          :disabled="countyStatus"
                          placeholder="区县"
                          @change="countyChange"
                        >
                          <el-option
                            v-for="(item,index) in county"
                            :key="index"
                            :label="item.label"
                            style="padding: 0 20px"
                            :value="index"
                          />
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
              <el-row
                class="mt-20"
                :gutter="20"
              >
                <el-col :span="24">
                  <el-form-item label="公司">
                    <el-input
                      v-model="ruleForm.company"
                      placeholder="请输入公司名称"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row
                class="mt-20"
                :gutter="20"
              >
                <el-col :span="24">
                  <el-form-item label="其他">
                    <el-input
                      v-model="ruleForm.more"
                      type="textarea"
                      :rows="5"
                      placeholder="其他您想要告诉我们的"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <div
                class="buttonShen"
                @click="submitForm('ruleForm')"
              >
                申请试用
              </div>
            </el-form>
          </div>
          <!-- phone -->
          <div
            v-if="showWin==3"
            class="phone_con"
          >
            <div class="img_con">
              <img
                src="../assets/imgs/floatBut/phone.png"
                alt=""
              >
            </div>
            <div class="data_con">
              <div>欢迎致电，我们将随时为您提供帮助!</div>
              <div>400-0999-235</div>
            </div>
          </div>
          <div
            v-if="showWin==3"
            class="phone_but"
          >
            <div class="but_con">
              <div class="but_tit">
                售前咨询
              </div>
              <div class="but_data">
                赵经理：17610358522<br>
                石经理：15010612217<br>
              </div>
            </div>
            <div class="but_con">
              <div class="but_tit">
                技术咨询
              </div>
              <div class="but_data">
                张先生：13131059599<br>
                云先生：18610941078<br>
              </div>
            </div>
            <div class="but_con">
              <div class="but_tit">
                咨询群聊
              </div>
              <div class="but_data">
                QQ群：462151894<br>
              </div>
            </div>
          </div>
        </div>
        <!-- 功能more -->
        <div
          v-if="openImg==1"
          class="page_box"
        >
          <img
            class="qrcode"
            src="../assets/imgs/footer/ma.png"
            alt=""
          >
        </div>
        <div
          v-if="openImg==4"
          class="box_more"
        >
          <div class="more_title">
            功能列表展示
          </div>
          <div class="more_con">
            <div class="more_left">
              <div
                v-for="item in moreBut"
                :key="item.id"
                class="but_con"
                :style="{color:(item.active?'#FEFEFE':'#333333'),borderColor:(item.active?'transparent':'#DCDCDC'),background:(item.active?'rgba(29, 34, 49, 1)':'#EEEEEE')}"
                @click="butAct(item)"
              >
                {{ item.name }}
              </div>
            </div>
            <div class="more_right">
              <div class="box_img">
                <img
                  v-for="(item,index) in moreBut[moreAct].imgs"
                  :key="index"
                  class="more_img"
                  :style="item.sty"
                  :src="item.img"
                  alt=""
                >
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script >
// eslint-disable-next-line camelcase
import more1_1 from '@/assets/imgs/buying/more1_1.png';
// eslint-disable-next-line camelcase
import more2_1 from '@/assets/imgs/buying/more2_1.png';
// eslint-disable-next-line camelcase
import more3_1 from '@/assets/imgs/buying/more3_1.png';
import areac from '../assets/js/areac';

export default {
  name: 'FloatButton',
  components: {
  },
  data() {
    return {
      wit: '50%',
      moreAct: 0,
      dialogFormVisible: false,
      openImg: 1,
      showWin: 1,
      prohibit: true,
      province: [],
      provinceIndex: null, // 省的下标
      city: [],
      cityIndex: null, // 城市的下标
      cityStatus: true,
      county: [],
      countyStatus: true,
      countyIndex: null, // 区县的下标
      /** ******************表单的验证************** */
      ruleForm: {
        name: '',
        duty: '',
        phone: '',
        email: '',
        company: '',
        more: '',
        provinceValue: '',
        provinceCode: '',
        cityValue: '',
        cityCode: '',
        countyValue: '',
        address: '',
        areaCode: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入您的联系电话', trigger: 'blur' },
        ],
        provinceValue: [
          { required: true, message: '请选择您的省份', trigger: 'change' },
        ],
        cityValue: [
          { required: true, message: '请选择您的城市', trigger: 'change' },
        ],
        countyValue: [
          { required: true, message: '请选择您的区县', trigger: 'change' },
        ],
      },
      indexS: 0,
      moreBut: [
        {
          id: 0,
          name: '工作',
          active: 1,
          imgs: [{
            // eslint-disable-next-line camelcase
            img: more1_1,
            sty: {
              // height: '732px'
            },
          }],
        },
        {
          id: 1,
          name: '管理',
          active: 0,
          imgs: [{
            // eslint-disable-next-line camelcase
            img: more2_1,
            sty: {
              // height: '570px'
            },
          }],
        },
        {
          id: 2,
          name: '开发',
          active: 0,
          imgs: [{
            // eslint-disable-next-line camelcase
            img: more3_1,
            sty: {
              // height: '563px'
            },
          }],
        },
      ],
    };
  },
  watch: {
    '$store.state.winAct': function (e) {
      this.dialogFormVisible = e;
      if (this.$store.state.moreAct) {
        this.openImg = 4;
        this.wit = '70%';
      } else if (this.$store.state.addAct) {
        this.openImg = 2;
        this.showWin = 1;
        this.wit = '50%';
      } else {
        this.openImg = 2;
        this.showWin = 2;
        this.wit = '50%';
      }
    },
    dialogFormVisible(e) {
      const self = this;
      !e ? self.$store.commit('winActChange', false) : '';
    },
  },
  created() { // 创建vue实例后
    this.getCity();
    this.prohibit = true;
    this.showWin = 1;
  },
  destroyed() { // vue实例销毁后

  },
  methods: {
    openqq() {
      window.open('http://shang.qq.com/wpa/qunwpa?idkey=3d0bc04e6a1e43055b8e69fd8324c477a45fa1cba57cfcd03dc95ba61427c52c');
    },
    openWin(e) {
      this.openImg = e;
      this.showWin = 1;
      this.wit = '50%';
      this.dialogFormVisible = !this.dialogFormVisible;
    },
    showOpen(e) {
      this.showWin = e;
    },
    toggTab(e) {
      const self = this;
      self.indexS = e.id;
      self.listArr.filter(item => (e.id === item.id ? item.active = true : item.active = false));
    },
    areaNo() {
      this.addressShow = false;
    },
    // 注册地址选择成功
    areaOk(data) {
      const self = this;
      let city = '';
      data.forEach((val, index) => {
        if (index !== 2) {
          city += `${val.name},`;
        } else {
          city += `${val.name}`;
        }
      });
      self.ruleForm.provinceValue = data[0].name;
      self.ruleForm.provinceCode = data[0].code;
      self.ruleForm.cityValue = data[1].name;
      self.ruleForm.cityCode = data[1].code;
      self.ruleForm.countyValue = data[2].name;
      self.ruleForm.areaCode = data[2].code;
      this.ruleForm.address = city;
      this.addressShow = false;
    },
    // 点击选中县城
    countyChange(val) {
      this.ruleForm.countyValue = this.county[val].label;
      this.ruleForm.areaCode = this.county[val].value;
    },
    // 点击选中市
    cityChange(val) {
      this.cityIndex = val;
      this.ruleForm.countyValue = '';
      this.ruleForm.areaCode = '';
      this.county = this.province[this.provinceIndex].children[val].children;
      this.countyStatus = false;
      this.ruleForm.cityValue = this.city[val].label;
      this.ruleForm.cityCode = this.city[val].value;
    },
    // 点击选中省
    provinceChange(val) {
      this.provinceIndex = val;
      this.county = '';
      this.ruleForm.cityValue = '';
      this.ruleForm.countyValue = '';
      this.ruleForm.cityCode = '';
      this.ruleForm.areaCode = '';
      this.city = this.province[val].children;
      this.cityStatus = false;
      this.ruleForm.provinceValue = this.province[val].label;
      this.ruleForm.provinceCode = this.province[val].value;
    },
    // 获取三级联查的各个地区
    getCity() {
      this.province = areac.data.filter(item => [
        item.value,
        item.label,
      ]);
    },
    // 马上加入
    submitForm(formName) {
      const self = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (self.prohibit) {
            self.prohibit = false;
            self.$ajax({
              url: '/je/product/crm/customer/saveSaleLead',
              data: {
                typeCode: 'jepaas',
                name: self.ruleForm.name,
                telePhone: self.ruleForm.phone,
                corporateName: self.ruleForm.company,
                provinceName: self.ruleForm.provinceValue,
                provinceCode: self.ruleForm.provinceCode,
                cityName: self.ruleForm.cityValue,
                cityCode: self.ruleForm.cityCode,
                areaName: self.ruleForm.countyValue,
                areaCode: self.ruleForm.areaCode,
                duties: self.ruleForm.duty,
                email: self.ruleForm.email,
                describe: self.ruleForm.more,
              },
            }).then(() => {
              self.prohibit = true;
              this.$message.success('提交成功请静候佳音!');
              self.ruleForm.name = '';
              self.ruleForm.phone = '';
              self.ruleForm.company = '';
              self.ruleForm.provinceValue = '';
              self.ruleForm.provinceCode = '';
              self.ruleForm.cityValue = '';
              self.ruleForm.cityCode = '';
              self.ruleForm.countyValue = '';
              self.ruleForm.areaCode = '';
              self.ruleForm.duty = '';
              self.ruleForm.email = '';
              self.ruleForm.more = '';
              this.$refs[formName].resetFields();
              this.dialogFormVisible = false;
            }).catch(() => {

            });
          }
        } else {
          this.$message.error('请将所有必填信息填写完整');
          return false;
        }
      });
    },
    butAct(e) {
      const self = this;
      self.moreAct = e.id;
      self.moreBut.map(item => (item.id == e.id ? item.active = 1 : item.active = 0));
    },
  },
};
</script>

<style lang='less' scoped >
  .float_con {
    position: fixed;
    top: 75vh;
    right: 2%;
    width: 42px;
    //   height:128px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 10px 0px rgba(255, 48, 65, 0.1);
    z-index: 10;
    border-radius: 3px;
    div {
      cursor: pointer;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      img {
        width: 20px;
        height: 20px;
      }
    }
    div:nth-child(1) {
      line-height: 42px;
      background: rgba(255, 48, 65, 1);
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      img {
        width: 20px;
        height: 22px;
      }
    }
    div:nth-child(1):after {
      content: "";
      height: 42px;
      width: 42px;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: -1;
      transition: background 0.7s;
    }
    div:nth-child(1):hover:after {
      z-index: 2;
      background: rgba(51, 51, 51, 0.1);
    }
    div:nth-child(2) {
      line-height: 42px;
      transition: background 0.7s;
    }
    div:nth-child(2):after {
      content: "";
      height: 42px;
      width: 42px;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: -1;
      transition: background 0.7s;
    }
    div:nth-child(2):hover:after {
      z-index: 2;
      background: rgba(51, 51, 51, 0.1);
    }
    div:nth-child(3) {
      line-height: 42px;
      border-top: 1px solid rgba(248, 248, 248, 1);
      transition: background 0.7s;
    }
    div:nth-child(3):after {
      content: "";
      height: 42px;
      width: 42px;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: -1;
      transition: background 0.7s;
    }
    div:nth-child(3):hover:after {
      z-index: 2;
      background: rgba(51, 51, 51, 0.1);
    }
  }
  .page_box {
    height: 630px;
    .qrcode {
      margin-top: 200px;
      width: 200px;
      height: 200px;
    }
    .title_con {
      width: 82%;
      height: 60px;
      margin: 0 auto 20px auto;
      border-bottom: 1px solid #cccccc;
      text-align: left;
      span {
        cursor: pointer;
        display: inline-block;
        width: 110px;
        text-align: center;
        height: 58px;
        padding: 0 10px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
        border-bottom: 2px solid rgba(51, 51, 51, 1);
      }
    }
    .qq_con {
      .img_con {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 80px;
        text-align: left;
        img {
          width: 150px;
          height: 175px;
          margin-top: 30px;
        }
        .data_con {
          text-align: left;
          div:nth-child(1) {
            height: 40px;
            font-size: 18px;
            font-weight: 400;
            color: rgba(51, 51, 51, 1);
          }
          div:nth-child(2) {
            height: 50px;
            font-size: 30px;
            font-weight: 400;
            color: rgba(51, 51, 51, 1);
          }
        }
      }
      .buttonShen {
        cursor: pointer;
        width: 300px;
        margin: 0px auto;
        margin-top: 50px;
        height: 50px;
        background: rgba(255, 48, 65, 1);
        box-shadow: 2px 2px 10px 0px rgba(252, 44, 54, 0.4);
        border-radius: 30px;
        line-height: 50px;
        font-size: 18px;
        font-weight: 400;
        color: rgba(254, 255, 255, 1);
        text-align: center;
        position: relative;
      }
      .buttonShen::after {
        content: "";
        width: 300px;
        height: 50px;
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 30px;
        z-index: -1;
        transition: background 0.7s;
      }
      .buttonShen:hover:after {
        z-index: 2;
        background: rgba(51, 51, 51, 0.1);
      }
    }
    .phone_con {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 120px;
      .img_con {
        width: 92px;
        height: 92px;
        margin-right: 30px;
        img {
          width: 92px;
          height: 92px;
        }
      }
      .data_con {
        text-align: left;
        div:nth-child(1) {
          height: 40px;
          font-size: 18px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
        }
        div:nth-child(2) {
          height: 50px;
          font-size: 50px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
        }
      }
    }
    .phone_but {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 75px 0;
      .but_con {
        width: 190px;
        height: 200px;
        background: rgba(240, 240, 240, 1);
        border-radius: 4px;
        // padding:50px 20px;
        .but_tit {
          margin: 40px 0 20px 0;
          font-size: 18px;
          font-weight: bold;
          color: rgba(51, 51, 51, 1);
          line-height: 32px;
        }
        .but_data {
          font-size: 14px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          line-height: 24px;
        }
      }
    }
    .from_con {
      width: 750px;
      height: 550px;
      margin: 0px auto;
      overflow: hidden;
      text-align: left;
      .mt-20 {
        margin-top: 3px;
      }
      .buttonShen {
        cursor: pointer;
        width: 480px;
        margin: 15px auto;
        height: 60px;
        background: rgba(255, 48, 65, 1);
        box-shadow: 2px 2px 10px 0px rgba(252, 44, 54, 0.4);
        border-radius: 30px;
        line-height: 60px;
        font-size: 18px;
        font-weight: 400;
        color: rgba(254, 255, 255, 1);
        text-align: center;
        position: relative;
      }
      .buttonShen::after {
        content: "";
        width: 480px;
        height: 60px;
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 30px;
        z-index: -1;
        transition: background 0.7s;
      }
      .buttonShen:hover:after {
        z-index: 2;
        background: rgba(51, 51, 51, 0.1);
      }
    }
  }
  .box_more {
    height: 700px;
    overflow-y: auto;
    .more_title {
      line-height: 100px;
      font-size: 30px;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
    }
    .more_con {
      display: flex;
      justify-content: space-around;
      .more_left {
        .but_con {
          cursor: pointer;
          width: 200px;
          height: 60px;
          line-height: 60px;
          border-bottom: 1px solid #dcdcdc;
          font-size: 18px;
          font-weight: 400;
          color: rgba(254, 254, 254, 1);
        }
      }
      .more_right {
        .more_div {
          display: flex;
          flex-wrap: wrap;
          width: 960px;
          .more_but {
            width: 120px;
            height: 120px;
            margin: 10px 8px;
            background: rgba(236, 243, 249, 1);
            border-radius: 20px;
            line-height: 120px;
            font-size: 18px;
            font-weight: 400;
            color: rgba(51, 51, 51, 1);
          }
        }
        .box_img {
          .more_img {
            display: block;
            width: 960px;
            // height: 732px;
          }
        }
      }
    }
  }
</style>
