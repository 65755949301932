/*
 * @Descripttion:
 * @Author: 宋爽爽
 * @Date: 2020-03-18 11:40:06
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-12-26 09:10:50
 */
import Cookies from 'js-cookie';

const TokenKey = 'jepaasAuthorization';

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, {
    expires: 7, // 七天有效
  });
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}
